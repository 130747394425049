import moment from 'moment'
import * as React from 'react'
import {
  PromotionDrawingDetailsResponse,
  PromotionDrawingsResponse,
  PromotionResponse,
  PromotionType,
  WinnerStatus,
} from '../../../api/promotions-fetcher/constants'
import { getBlockClass } from '../../../utilities/helpers'
import { BackButtonUI } from '../../CommonComponents/BackButtonComponent/back-button-ui'
import { LoadingDots } from '../../CommonComponents/loading-dots/loading-dots-ui'
import {
  DrawingSelection,
  WinnersManagementDrawingForm,
  WinnersManagementDrawingFormProps,
} from './winners-management-drawing-form/winners-management-drawing-form'
import { WinnersManagementTierContainer } from './winners-management-tier/winners-management-tier-container'
import { WinnersManagementTierContext } from './winners-management-list-context'
import { WinnersManagementOpenClaimsFilter } from './winners-management-open-claims-filter'
import './styles.scss'

export type WinnersManagementUIProps = Omit<
  WinnersManagementDrawingFormProps,
  'setSelectedDrawing' | 'loading'
> & {
  promotionLoading: boolean
  promotionId: string
  promotion?: PromotionResponse
  tiersLoading: boolean
  tiersError?: string
  drawingTiers?: PromotionDrawingDetailsResponse
  drawingsLoading: boolean
  drawings?: PromotionDrawingsResponse
  firstTierLoad: boolean
  fetchDownloadWinnersCSV: (
    promotionId: string,
    drawingGroupId: string,
    drawingId: string
  ) => Promise<void>
}

const ROOT_CLASS = 'winners-management'

export const WinnersManagementUI = (props: WinnersManagementUIProps) => {
  const [openFilterChecked, setOpenFilterChecked] =
    React.useState<boolean>(false)
  const [selectedDrawing, setSelectedDrawing] =
    React.useState<DrawingSelection>()
  const [drawingsList, setDrawingsList] = React.useState<DrawingSelection[]>()
  const [filteredTiersList, setFilteredTiersList] =
    React.useState<PromotionDrawingDetailsResponse>()

  const toggleOpenFilter = () => setOpenFilterChecked(!openFilterChecked)

  React.useEffect(() => {
    const allDrawings = props.drawings
      ?.map((drawingGroup): DrawingSelection[] => {
        return drawingGroup.drawings.map((drawing): DrawingSelection => {
          const formattedDate = drawing.scheduledDrawTime
            ? ` • ${moment(drawing.scheduledDrawTime).format('MM/DD/YYYY')}`
            : ''
          return {
            drawingGroupId: drawingGroup.id,
            drawingId: drawing.id,
            displayName: `${drawingGroup.name} ${drawing.drawingNumber}`,
            displayNameWithDate: `${drawingGroup.name} ${drawing.drawingNumber}${formattedDate}`,
          }
        })
      })
      .reduce((a, b) => a.concat(b))

    setDrawingsList(allDrawings)
  }, [props.drawings])

  React.useEffect(() => {
    if (openFilterChecked) {
      const filteredList = props.drawingTiers?.map((tier) => {
        const filteredWinners = tier.winners.filter(
          (winner) => winner.status === WinnerStatus.PENDING
        )
        return {
          ...tier,
          winners: filteredWinners,
        }
      })
      setFilteredTiersList(filteredList)
    } else {
      setFilteredTiersList(props.drawingTiers)
    }
  }, [props.drawingTiers, openFilterChecked])

  return (
    <div className={ROOT_CLASS}>
      <BackButtonUI tabIndex={1} message="Back to Promotions" />
      <header className={getBlockClass(ROOT_CLASS, 'header')}>
        {props.promotionLoading ? (
          <LoadingDots id="promotion-loading-dots-header" color="white" />
        ) : (
          PromotionType[props.promotion?.type as keyof typeof PromotionType]
        )}
      </header>
      <WinnersManagementOpenClaimsFilter
        openClaimsFilterChecked={openFilterChecked}
        toggleOpenClaimsFilter={toggleOpenFilter}
      />
      <WinnersManagementDrawingForm
        loading={props.drawingsLoading || props.tiersLoading}
        drawingsList={drawingsList}
        drawingsError={props.drawingsError}
        fetchDrawingDetails={props.fetchDrawingDetails}
        setSelectedDrawing={setSelectedDrawing}
      />
      <section className={getBlockClass(ROOT_CLASS, 'title')}>
        {props.promotionLoading ? (
          <LoadingDots id="promotion-loading-dots" color="white" />
        ) : (
          props.promotion?.name
        )}
        {selectedDrawing && !(props.tiersLoading && props.firstTierLoad)
          ? ` - ${selectedDrawing.displayName}`
          : ''}
      </section>
      {props.tiersError && (
        <div className={getBlockClass(ROOT_CLASS, 'tier-message')}>
          Something went wrong
        </div>
      )}

      {!(props.tiersLoading && props.firstTierLoad) &&
        props.promotion &&
        !props.promotionLoading &&
        !props.tiersError &&
        selectedDrawing &&
        ((props.drawingTiers?.length ?? 0) === 0 ? (
          <div className={getBlockClass(ROOT_CLASS, 'tier-message')}>
            No tier data available
          </div>
        ) : (
          filteredTiersList?.map((tier) => {
            const fullTier = props.drawingTiers?.find(
              (x) => x.prizeTier === tier.prizeTier
            )
            const totalWinners =
              fullTier?.winners.filter(
                (winner) => winner.status !== WinnerStatus.DECLINED
              ).length ?? 0
            const acceptedWinners =
              fullTier?.winners.filter((x) => Boolean(x.claimAcceptedDate))
                .length ?? 0
            return (
              <WinnersManagementTierContext.Provider
                key={tier.prizeTier}
                value={{
                  promotionId: props.promotionId,
                  drawingGroupId: selectedDrawing.drawingGroupId,
                  drawingId: selectedDrawing.drawingId,
                  refreshTiers: async () =>
                    props.fetchDrawingDetails(
                      selectedDrawing.drawingGroupId,
                      selectedDrawing.drawingId
                    ),
                }}
              >
                <div
                  className={getBlockClass(ROOT_CLASS, 'tier')}
                  key={tier.prizeTier}
                >
                  {props.promotion && (
                    <WinnersManagementTierContainer
                      key={tier.prizeTier}
                      tier={tier}
                      selectedDrawing={selectedDrawing}
                      totalWinners={totalWinners}
                      acceptedWinners={acceptedWinners}
                      promotion={props.promotion}
                      fetchDownloadWinnersCSV={props.fetchDownloadWinnersCSV}
                    />
                  )}
                </div>
              </WinnersManagementTierContext.Provider>
            )
          })
        ))}
    </div>
  )
}
