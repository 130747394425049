import React from "react";
import CustomCard from './../../../dashboard/customCard';
import './AdministrationButtons.css'
import {checkRenderPermissions} from './../../../helpers';
import {permissions} from './../../../constants';
import {inject} from "mobx-react";
import {administrationRoutePath} from './../../../config.js';
import {downloadMonthlyUserCSV} from "../../../util";

@inject('playerStore', 'authStore')
class AdministrationButtons extends React.Component {

    componentDidMount() {
        localStorage.removeItem('searchFirstName');
        localStorage.removeItem('searchLastName');
        localStorage.removeItem('phone');
        localStorage.removeItem('email');
    }

    navigateTo = (path) => {

        // if (path === `${ administrationRoutePath }/rolemanagement`)
        //     this.props.playerStore.logAction(UserActionCategory.PAGE_VIEW, UserAction.PLAYER_MAINTENANCE);
        // else if (path === `${ administrationRoutePath }/usermanagement`)
        //     this.props.playerStore.logAction(UserActionCategory.PAGE_VIEW, UserAction.PLAYER_SERVICE_REPORTS);

        this.props.history.push(path);
    }

    renderPlayerSupport() {
        return (
            <div className='app-center-body' style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                marginLeft: "0px",
                marginTop: "7em",
                alignItems: "center"
            }}>


                {checkRenderPermissions(permissions.CAN_ACCESS_USER_MANAGEMENT, JSON.parse(localStorage.getItem('userpolicies'))) &&
                    <CustomCard
                        title='User Management'
                        handleCallback={() => this.navigateTo(`${administrationRoutePath}/usermanagement`)}
                    />
                }

                {checkRenderPermissions(permissions.CAN_ACCESS_ROLE_MANAGEMENT, JSON.parse(localStorage.getItem('userpolicies'))) &&
                    <CustomCard
                        title='Role Managment'
                        handleCallback={() => this.navigateTo(`${administrationRoutePath}/rolemanagement`)}
                    />
                }

                {checkRenderPermissions(permissions.CAN_ACCESS_SETTINGS, JSON.parse(localStorage.getItem('userpolicies'))) &&
                    <CustomCard
                        title='Settings'
                        handleCallback={() => this.navigateTo(`${administrationRoutePath}/admin/settings`)}
                    />
                }

                {checkRenderPermissions(permissions.CAN_SEE_MONTHLY_USERS, JSON.parse(localStorage.getItem('userpolicies'))) &&
                    <CustomCard
                        title='Back Office User Export'
                        handleCallback={() => downloadMonthlyUserCSV().then()}
                    />
                }

                {checkRenderPermissions(permissions.CAN_ACCESS_FREQUENT_CASHER_MODULE, JSON.parse(localStorage.getItem('userpolicies'))) &&
                    <CustomCard
                        title='Frequent Casher'
                        handleCallback={() => this.navigateTo(`${administrationRoutePath}/frequent-casher`)}
                    />
                }
            </div>
        );
    }

    render() {
        var renderObj;

        renderObj = this.renderPlayerSupport();
        // if (checkRenderPermissions(permissions.SEE_PLAYER_SUPPORT, JSON.parse(localStorage.getItem('userpolicies')))) {
        //     renderObj = this.renderPlayerSupport();
        // } else {
        //     renderObj =
        //         <h1 class='unauthorized-header'>You do not have permission to view this page! Please contact your System
        //             Administrator!</h1>
        // }

        return (
            renderObj
        );
    }
}

export default AdministrationButtons;