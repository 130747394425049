import compact from 'lodash/compact'
import moment from 'moment'
import { FormikProps } from 'formik'
import {
  PrizeType,
  PromotionBuilderDrawing,
  PromotionBuilderDrawingGroup,
  PromotionBuilderPrizeTier,
  PromotionBuilderRule,
  PromotionStatus,
  PromotionType,
} from '../../../../../api/promotions-fetcher/constants'
import { getBlockClass } from '../../../../../utilities/helpers'
import { GenericSidebarFormMode } from '../../../../GenericSidebar/generic-sidebar-ui'
import {
  PromotionCampaignValidation,
  PromotionCampaignValidationDrawingGroup,
  PromotionCampaignValidationPrizeTier,
  PromotionCampaignValidationRule,
} from './validation'

export const FORMIK_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss'

export const VALID_GAME_ID_LENGTH = 4
export const TAXABLE_PRIZE_AMOUNT = 601

export const PROMOTION_SIDEBAR_FORM_ROOT_CLASS =
  'promotion-campaign-sidebar-form'
export const PROMOTION_SIDEBAR_FORM_CLASS = getBlockClass(
  PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
  'form'
)
export const PROMOTION_SIDEBAR_FORM_ITEM_CLASS = getBlockClass(
  PROMOTION_SIDEBAR_FORM_CLASS,
  'form-item'
)

export type PromotionCampaignBuilderSubFormProps = {
  formMode: GenericSidebarFormMode
  isLoading: boolean
  formik: FormikProps<PromotionCampaignValidation>
  promotionStatus?: PromotionStatus
  canEditPromotionCampaigns: boolean
}

export const dollarsToCentsField = (dollars?: number) =>
  dollars ? dollars * 100 : undefined

export const centsToDollarsField = (cents?: number) =>
  cents ? cents / 100 : undefined

export const promotionIsComplete = (promotionStatus?: PromotionStatus) =>
  Boolean(promotionStatus) && promotionStatus === PromotionStatus.COMPLETE

export const promotionIsNewOrPending = (promotionStatus?: PromotionStatus) =>
  !promotionStatus || promotionStatus === PromotionStatus.PENDING

export const promotionIsNotComplete = (promotionStatus?: PromotionStatus) =>
  promotionStatus !== PromotionStatus.COMPLETE

export const getMinimumDateForDrawing = (
  promotionStartDate: string,
  drawings: PromotionBuilderDrawing[],
  index: number,
  considerDrawTimes: boolean
) => {
  let consideredDates = [
    moment(),
    moment(promotionStartDate ? promotionStartDate : undefined),
    ...drawings
      .slice(0, index)
      .map((drawing) =>
        moment(
          drawing.scheduledDrawCollectionEndTime
            ? drawing.scheduledDrawCollectionEndTime
            : undefined
        )
      ),
  ]

  if (considerDrawTimes) {
    consideredDates = [
      ...consideredDates,
      ...drawings
        .slice(0, index)
        .map((drawing) =>
          moment(
            drawing.scheduledDrawTime ? drawing.scheduledDrawTime : undefined
          )
        ),
    ]
  }

  return moment.max(consideredDates)
}

export const getMaximumDateForDrawing = (
  drawings: PromotionBuilderDrawing[],
  index: number,
  considerDrawTimes: boolean
) => {
  let laterDrawingDates = compact([
    ...drawings
      .slice(index + 1)
      .map((drawing) =>
        drawing.scheduledDrawCollectionEndTime
          ? moment(drawing.scheduledDrawCollectionEndTime)
          : undefined
      ),
  ])

  if (considerDrawTimes) {
    laterDrawingDates = compact([
      ...laterDrawingDates,
      ...drawings
        .slice(index + 1)
        .map((drawing) =>
          drawing.scheduledDrawTime
            ? moment(drawing.scheduledDrawTime)
            : undefined
        ),
    ])
  }
  return laterDrawingDates.length > 0
    ? moment.min(laterDrawingDates)
    : undefined
}

export const getDrawFilePrefix = (promotionType: string) => {
  switch (promotionType) {
    case PromotionType.SCAN_TO_ENTER:
      return 'MAS2E-'
    case PromotionType.SECOND_CHANCE:
      return 'MA-'
    case PromotionType.REGISTRATION:
      return 'D'
    case PromotionType.MANUAL_WINNER_ENTRY:
      return 'M'
    case PromotionType.ZIP_TRIP:
    default:
      return ''
  }
}

export const mapInitialDrawing = (drawing: PromotionBuilderDrawing) => ({
  id: drawing.id,
  drawingNumber: drawing.drawingNumber,
  scheduledDrawTime: Boolean(drawing.scheduledDrawTime)
    ? moment(drawing.scheduledDrawTime).format(FORMIK_DATE_FORMAT)
    : '',
  scheduledDrawCollectionEndTime: Boolean(
    drawing.scheduledDrawCollectionEndTime
  )
    ? moment(drawing.scheduledDrawCollectionEndTime).format(FORMIK_DATE_FORMAT)
    : '',
})

export const mapInitialPrizeTier = (tier: PromotionBuilderPrizeTier) => ({
  id: tier.id,
  tierName: tier.tierName,
  prizeType: tier.prizeType,
  prizeValueInDollars: centsToDollarsField(tier.prizeValueInCents),
  numWinners: tier.numWinners,
  description: tier.description,
  eventAllocationInDollars: centsToDollarsField(tier.eventAllocationInCents),
  experienceValueInDollars: centsToDollarsField(tier.experienceValueInCents),
})

export const mapRules = (
  rules: PromotionCampaignValidationRule[]
): PromotionBuilderRule[] => {
  return rules.map((rule, index) => {
    return {
      gameIds: rule.gameIds,
      predicates: rule.predicates,
      quantity: rule.quantity ?? 1,
      id: rule.id,
    }
  })
}

export const mapDrawingGroup = (
  isBonus: boolean,
  drawFilePrefix: string,
  promotionType: string,
  drawings: PromotionBuilderDrawing[],
  prizeTiers: PromotionCampaignValidationPrizeTier[],
  drawingGroup?: PromotionCampaignValidationDrawingGroup
): PromotionBuilderDrawingGroup => {
  return {
    id: drawingGroup?.id,
    name: drawingGroup?.name ?? `${isBonus ? 'Bonus ' : ''}Drawing`,
    drawFilePrefix:
      promotionType === PromotionType.SECOND_CHANCE ||
      promotionType === PromotionType.SCAN_TO_ENTER
        ? drawFilePrefix
        : getDrawFilePrefix(promotionType),
    drawings: drawings.map((drawing) => ({
      id: drawing.id,
      drawingNumber: drawing.drawingNumber,
      scheduledDrawTime: Boolean(drawing.scheduledDrawTime)
        ? drawing.scheduledDrawTime
        : undefined,
      scheduledDrawCollectionEndTime: Boolean(
        drawing.scheduledDrawCollectionEndTime
      )
        ? drawing.scheduledDrawCollectionEndTime
        : undefined,
    })),
    prizeTiers: prizeTiers.map((prizeTier, index) => {
      if (!prizeTier.prizeType) {
        throw new Error('Prize Type not defined')
      }
      return {
        id: prizeTier.id,
        tierName: prizeTier.tierName || (index + 1).toString(),
        prizeType: prizeTier.prizeType,
        prizeValueInCents:
          dollarsToCentsField(
            prizeTier.prizeType !== PrizeType.ALTERNATE
              ? prizeTier.prizeValueInDollars
              : undefined
          ) ?? 0,
        numWinners: prizeTier.numWinners ?? 0,
        description:
          prizeTier.prizeType !== PrizeType.ALTERNATE
            ? prizeTier.description ?? ''
            : 'Alternate Winners',
        eventAllocationInCents: dollarsToCentsField(
          prizeTier.prizeType === PrizeType.EXPERIENCE
            ? prizeTier.eventAllocationInDollars
            : undefined
        ),
        experienceValueInCents: dollarsToCentsField(
          prizeTier.prizeType === PrizeType.EXPERIENCE
            ? prizeTier.experienceValueInDollars
            : undefined
        ),
      }
    }),
  }
}
