import * as React from 'react'
import { PlayerPromotionsResponse } from '../../../../api/promotions-fetcher/use-fetch-promotion-entries'
import { PaginationCommonComponent } from '../../../../components/CommonComponents/PaginationCommonComponent'
import { getPageData } from '../../../../util'
import './styles.scss'

const ROOT_CLASS = 'player-promotion-entries'
const FOOTER_CLASS = `${ROOT_CLASS}-footer`

export type PlayerPromotionEntriesProps = PlayerPromotionEntriesTableProps & {
  isLoading: boolean
  error?: string
}

export const PlayerPromotionEntriesUI = (
  props: PlayerPromotionEntriesProps
) => {
  return (
    <section className={ROOT_CLASS}>
      <div>
        {/* TO DO: error and loading states */}
        <PlayerPromotionEntriesTable
          promotions={props.promotions}
          setOpenPromotionDrawing={props.setOpenPromotionDrawing}
        />
      </div>
    </section>
  )
}

const TABLE_CLASS = 'player-promotion-entries-table'
const SMALL_CLASS = 'small'
const getTotalPages = (dataLength: number): number =>
  dataLength > 0 ? Math.ceil(dataLength / 10) : 1

const TableHeaders = [
  { columnText: 'Promotion' },
  { columnText: 'Entries', class: SMALL_CLASS },
  { columnText: 'Drawing', class: SMALL_CLASS },
]

export type PlayerPromotionEntriesTableProps = {
  promotions?: PlayerPromotionsResponse
  setOpenPromotionDrawing: (promotionId: string, drawingId: string) => void
}

export const PlayerPromotionEntriesTable = (
  props: PlayerPromotionEntriesTableProps
) => {
  const [currentPage, setCurrentPage] = React.useState<number>(0)

  if (!props.promotions) {
    return <div>No promotions found.</div>
  }

  const pageData: PlayerPromotionsResponse = getPageData(
    props.promotions,
    currentPage
  )
  return (
    <>
      <table className={TABLE_CLASS}>
        <thead>
          <tr>
            {TableHeaders.map((header) => (
              <th key={header.columnText} className={header.class}>
                {header.columnText}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {pageData.map((row) => (
            <tr key={`${row.promotionId}-${row.drawingNumber}`}>
              <td
                className={`promotion-label`}
                onClick={() =>
                  props.setOpenPromotionDrawing(row.promotionId, row.drawingId)
                }
              >
                {row.promotionName}
              </td>
              <td className={SMALL_CLASS}>{row.entryCount}</td>
              <td className={SMALL_CLASS}>{row.drawingNumber}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={`${FOOTER_CLASS}`}>
        <div tabIndex={0} className={`${FOOTER_CLASS}__page-label`}>{`Page ${
          currentPage + 1
        } of ${getTotalPages(props.promotions.length)}`}</div>
        <PaginationCommonComponent
          playerData={props.promotions}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  )
}
