import moment from 'moment-timezone'
import { InputValidationMessages } from '../../../Constants/InputValidationMessages'
import config from '../../../config'
import { makeHttpCall } from '../../../services/MakeHttpCall'
import { savePDF } from '../../../util/excel-export-util'

let formik: any
let setCurrentPage: any
let setShowErrorDialog: any
let setRequestCompleted: any
let setPayments: any
let setSearchParams: any

export const setVariables = (
  formikObj: any,
  setCurrentPageObj: any,
  setShowErrorDialogObj: any,
  setRequestCompletedObj: any,
  setAnnualBeanoReportsObj: any,
  setSearchParamsObj: any
) => {
  formik = formikObj
  setCurrentPage = setCurrentPageObj
  setShowErrorDialog = setShowErrorDialogObj
  setRequestCompleted = setRequestCompletedObj
  setPayments = setAnnualBeanoReportsObj
  setSearchParams = setSearchParamsObj
}

export const setFieldValue = (e: any, formik: any) => {
  if (e.target.name === 'annuityId') {
    formik.setFieldValue(e.target.name, e.target.value.trim(), true)
    formik.setFieldValue('paymentSource', 'Annuities', true)
  } else {
    formik.setFieldValue(e.target.name, e.target.value, true)
  }
}

export const validate = (values: any) => {
  const errors = {}

  if (values.startDate && values.endDate) {
    const a = moment(values.startDate)
    const b = moment(values.endDate)
    if (b.diff(a, 'days') < 0) {
      Object.assign(errors, {
        endDate: InputValidationMessages.NegativeDateRange,
      })
    }
  }

  /*    if (!values.startDate) {
            Object.assign(errors, {startDate: InputValidationMessages.RequiredField});
        }

        if (!values.endDate) {
            Object.assign(errors, {endDate: InputValidationMessages.RequiredField});
        }*/

  if (values.annuityId && values.paymentSource !== 'Annuities') {
    Object.assign(errors, {
      paymentSource: InputValidationMessages.PaymentSearchWithAnnuityID,
    })
  }

  return errors
}

export const onSubmit = async (values: any) => {
  getPayments(values)
}

export const clearSearch = (formik: any) => {
  setCurrentPage(0)

  formik.resetForm({
    values: {
      startDate: '',
      endDate: '',
      firstName: '',
      lastName: '',
      ssn: '',
      freeformName: '',
      checkNumber: '',
      annuityId: '',
      paymentSource: '',
    },
  })

  setPayments(null)

  // formik.submitForm();
}

export const getPageData = (payments: any[], page: number = 0) => {
  const array: any = []
  // let index = 0;
  for (let i = page * 10; i < page * 10 + 10; i++) {
    if (!(payments[i] === undefined)) {
      // payments[i].index = index;
      // index++;
      payments[i].index = i
      array.push(payments[i])
    }
  }
  return array
}

export const getPayments = async (values: any) => {
  // alert(JSON.stringify(values));

  setCurrentPage(0)

  setRequestCompleted(false) // Display Circular Progress

  const body: any = {}

  try {
    /*        if (values.startDate && values.endDate) {
            body.reportableDateRange = {
                startDate: values.startDate + 'T00:00:00.000Z',
                endDate: values.endDate + 'T23:59:59.999Z'
            }
        }*/

    if (values.startDate && values.endDate) {
      body.reportableDateRange = {
        startDate: moment(values.startDate)
          .startOf('day')
          .utc()
          .format()
          .replace('Z', '.000Z'),
        endDate: moment(values.endDate)
          .endOf('day')
          .utc()
          .format()
          .replace('Z', '.999Z'),
      }
    }

    if (values.ssn) {
      body.ssn = values.ssn
    }

    if (values.firstName) {
      body.firstName = values.firstName
    }

    if (values.lastName) {
      body.lastName = values.lastName
    }

    if (values.freeformName) {
      body.freeformName = values.freeformName
    }

    if (values.checkNumber) {
      body.checkNumber = values.checkNumber
    }

    if (values.annuityId) {
      body.annuityId = values.annuityId
    }

    if (values.paymentSource === 'Annuities') {
      body.source = 'ANNUITIES'
    } else if (values.paymentSource === 'Claim Center') {
      body.source = 'ES'
    } else if (values.paymentSource === 'Promotions') {
      body.source = 'PROMOTIONS'
    } else if (values.paymentSource === 'Remote Ticket Cashing') {
      body.source = 'RTC'
    } else if (values.paymentSource === 'Season Tickets') {
      body.source = 'SEASON_TICKETS'
    } else if (values.paymentSource === 'Other') {
      body.source = 'OTHER'
    } else if (values.paymentSource === 'Split') {
      body.source = 'SPLIT'
    }

    setSearchParams({
      startDate: values.startDate,
      endDate: values.endDate,
      firstName: values.firstName,
      lastName: values.lastName,
      ssn: values.ssn,
      freeformName: values.freeformName,
      checkNumber: values.checkNumber,
      annuityId: values.annuityId,
      paymentSource: values.paymentSource
    })

    // alert(JSON.stringify(body));

    const options = {
      method: 'POST',
      url: `${config.SERVER_BASE_URL}/api/v1/payments/search`,
      data: body,
    }

    let response = await makeHttpCall(options)
    setPayments(response)

    setRequestCompleted(true)
  } catch (e) {
    setRequestCompleted(true)
    setShowErrorDialog(true)
  }
}

export const exportPdfReport = async (pdfBytes: string, setBannerInfo: any) => {
  const now = moment().format('YYYYMMDD_HHMMSS')

  try {
      const pdfBuffer = Buffer.from(pdfBytes, 'base64')
      const filename = `payment_search_${now}`
      savePDF(pdfBuffer, filename)
  } catch(err) {
      setBannerInfo({message: `Error saving payment search report PDF | ${err}`, error: true})
  }
}
