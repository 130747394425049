import * as React from 'react'
import {
  FuturePayment,
  FuturePaymentSource,
  SearchFuturePaymentsRequest,
  SearchFuturePaymentsResponse,
} from '../../../../api/future-payments-fetcher/api-types'
import { formatCentsToDollars } from '../../../../util'
import { MaskedSsnUI } from '../../../CommonComponents/MaskedSsnComponent/masked-ssn-ui'
import {
  SearchResultsTableColumnMap,
  SearchResultsTableUI,
} from '../../../CommonComponents/SearchResultsTableComponent/search-results-table-ui'
import { OptionallyRenderSourceGuideCTA } from '../../SourceGuideButtonComponent'
import { CheckRunQueueSourceGuideUI } from '../check-run-queue-source-guide/check-run-queue-source-guide-ui'
import { FuturePaymentSearchAndFilterUI } from '../future-payment-search-and-filter/future-payment-search-and-filter-ui'
import { FuturePaymentStatusUI } from '../future-payment-status/future-payment-status-ui'
import { generateFuturePaymentsListCTA } from '../future-payments-list-row-cta/generate-future-payments-list-row-cta'
import { PendingCheckQueueAggregateTotalsUI } from '../pending-check-queue-aggregate-totals/pending-check-queue-aggregate-totals-ui'
import './styles.scss'
import { FutureTaxAdjustmentsUI } from '../future-tax-adjustments/future-tax-adjustments-ui'

const ROOT_CLASS = 'check-run-queue-all-future-payments'

type FuturePaymentDisplayRow = Omit<
  FuturePayment,
  'grossPaymentInCents' | 'source' | 'tin' | 'status' | 'onHoldNotes'
> & {
  grossPaymentDisplay: string
  sourceDisplay: string
  status: React.JSX.Element
  tin: React.JSX.Element
}

const FUTURE_PAYMENT_DISPLAY_SOURCE_MAP_ABBR: {
  [key in FuturePaymentSource]: string
} = {
  ANNUITIES: 'AN',
  PROMOTIONS: 'PR',
  SEASON_TICKETS: 'ST',
  OTHER: 'OTR',
}

const mapFuturePaymentToRow = (
  futurePayment: FuturePayment
): FuturePaymentDisplayRow => {
  return {
    futurePaymentId: futurePayment.futurePaymentId,
    name: futurePayment.name,
    tin: futurePayment.tin ? <MaskedSsnUI ssn={futurePayment.tin} /> : <></>,
    annuityAccountId: futurePayment.annuityAccountId,
    sourceDisplay: FUTURE_PAYMENT_DISPLAY_SOURCE_MAP_ABBR[futurePayment.source],
    status: (
      <FuturePaymentStatusUI
        status={futurePayment.status}
        position="left"
        notes={futurePayment.onHoldNotes}
      />
    ),
    grossPaymentDisplay: formatCentsToDollars(
      futurePayment.grossPaymentInCents
    ),
  }
}
const ALL_FUTURE_PAYMENTS_COLUMN_MAP: SearchResultsTableColumnMap<FuturePaymentDisplayRow> =
  [
    { dataKey: 'name', label: 'Payee Name', width: 14 },
    { dataKey: 'tin', label: 'TIN', width: 14 },
    { dataKey: 'grossPaymentDisplay', label: 'Gross Payment', width: 14 },
    { dataKey: 'annuityAccountId', label: 'Annuity ID', width: 14 },
    { dataKey: 'sourceDisplay', label: 'Source', width: 14 },
    { dataKey: 'status', label: 'Status', width: 14 },
  ]

export type AllFuturePaymentsUIProps = {
  futurePaymentsData?: SearchFuturePaymentsResponse
  searchHandler: (searchCriteria?: SearchFuturePaymentsRequest) => void
  searchCriteria?: SearchFuturePaymentsRequest
  setSearchCriteria: React.Dispatch<React.SetStateAction<SearchFuturePaymentsRequest | undefined>>
}

const getNoDataMessage = (searchCriteria?: SearchFuturePaymentsRequest) => {
  if (
    searchCriteria &&
    Object.values(searchCriteria).filter((value) => Boolean(value)).length !== 0
  ) {
    return 'No Results'
  }
  return 'There are no pending checks at this time'
}

export const AllFuturePaymentsUI = (props: AllFuturePaymentsUIProps) => {
  const {searchCriteria, setSearchCriteria} = props
  const [showSourceGuide, setShowSourceGuide] = React.useState<Boolean>(false)
  const [noDataMessage, setNoDataMessage] = React.useState<string>('')

  React.useEffect(() => {
    setNoDataMessage(getNoDataMessage(searchCriteria))
  }, [props.futurePaymentsData])

  return (
    <>
    <div className={ROOT_CLASS}>
      <FuturePaymentSearchAndFilterUI
        searchHandler={(params) => {
          setSearchCriteria(params)
          props.searchHandler(params)
        }}
      />
      <section className="aggregate-totals-section">
        <PendingCheckQueueAggregateTotalsUI
          totalAnnuitiesInCents={
            props.futurePaymentsData?.totalAnnuitiesPaymentsInCents ?? 0
          }
          totalPromotionsInCents={
            props.futurePaymentsData?.totalPromotionsPaymentsInCents ?? 0
          }
          totalSeasonTicketsInCents={
            props.futurePaymentsData?.totalSeasonTicketsPaymentsInCents ?? 0
          }
          totalOtherInCents={
            props.futurePaymentsData?.totalOtherPaymentsInCents ?? 0
          }
          totalGrossPaymentInCents={
            props.futurePaymentsData?.totalGrossPaymentsInCents ?? 0
          }
          includesOnHoldPayments={true}
        />
      </section>
      <SearchResultsTableUI
        rowData={
          props.futurePaymentsData?.futurePayments.map(mapFuturePaymentToRow) ??
          []
        }
        columnMap={ALL_FUTURE_PAYMENTS_COLUMN_MAP}
        generateRowCTA={generateFuturePaymentsListCTA}
        additionalResults={false}
        noDataMessage={noDataMessage}
      >
        <OptionallyRenderSourceGuideCTA
          setPaymentSourceGuide={setShowSourceGuide}
        />
      </SearchResultsTableUI>

      {showSourceGuide && (
        <CheckRunQueueSourceGuideUI
          setPaymentSourceGuide={setShowSourceGuide}
        />
      )}
    </div>
    <FutureTaxAdjustmentsUI
      taxAdjustments={props.futurePaymentsData?.taxAdjustments ?? []}
    />
    </>
  )
}
