import { useFormik } from 'formik'
import moment from 'moment'
import * as React from 'react'
import { FaRegCheckSquare, FaRegSquare } from 'react-icons/fa'
import {
  AllPromotionsResponse,
  PromotionsDateRangeReportProps,
} from '../../../api/promotions-fetcher/constants'
import { getBlockClass } from '../../../utilities/helpers'
import { CustomDropdownField } from '../../CommonComponents/custom-dropdown-component/custom-dropdown-ui'
import { DateAndTimeInput } from '../../CommonComponents/date-and-time-input-component/date-and-time-input-ui'
import './styles.scss'
type PromotionsDateRangeReportFilterComponentProps = {
  promotions?: AllPromotionsResponse
  reportLoading: boolean
  generateReport: (props: PromotionsDateRangeReportProps) => void
  resetData: () => void
}
const ROOT_CLASS = 'promotions-date-range-report-filter'

const validate = (values: PromotionsDateRangeReportProps) => {
  const errors: Record<string, string> = {}
  if (!values.selectedPromotion) {
    errors.selectedPromotion = 'A promotion needs to be selected'
  }
  if (!values.endDate) {
    errors.endDate = 'End Date should be set'
  }
  if (!values.startDate) {
    errors.startDate = 'Start Date should be set'
  }
  if (moment(values.endDate).isBefore(moment(values.startDate))) {
    errors.endDate = 'End date should be after the start date'
  }
  return errors
}

export const PromotionsDateRangeReportFilterUI = (
  props: PromotionsDateRangeReportFilterComponentProps
) => {
  const initialValues: PromotionsDateRangeReportProps = {
    selectedPromotion: props.promotions?.length ? props.promotions[0].id : '',
    startDate: '',
    endDate: '',
    disabledEntries: false,
  }
  const dateRangeForm = useFormik({
    initialValues,
    onSubmit: () => {},
    validate: validate,
  })

  const promotionOptions =
    props.promotions?.map((promotion) => {
      return {
        key: promotion.id,
        value: promotion.id,
        display: promotion.name,
      }
    }) ?? []

  return (
    <section className={ROOT_CLASS}>
      <header className={getBlockClass(ROOT_CLASS, 'header')}>Filter</header>
      <form
        aria-label="Promotion date range report form"
        className={getBlockClass(ROOT_CLASS, 'form')}
      >
        <label
          className={getBlockClass(ROOT_CLASS, 'label')}
          htmlFor="selectedPromotion"
        >
          Select Promotion
        </label>
        <CustomDropdownField
          disabled={props.reportLoading}
          onBlur={dateRangeForm.handleBlur}
          onChange={dateRangeForm.handleChange}
          value={dateRangeForm.values.selectedPromotion}
          options={promotionOptions}
          name="selectedPromotion"
          id="promotion-dropdown"
        />
        <div className={getBlockClass(ROOT_CLASS, 'date-controls-container')}>
          <DateAndTimeInput
            label="Start Date/Time"
            name="startDate"
            onChange={dateRangeForm.handleChange}
            onBlur={dateRangeForm.handleBlur}
            maxDate={dateRangeForm.values.endDate}
            value={dateRangeForm.values.startDate}
            error={dateRangeForm.errors.startDate}
            touched={dateRangeForm.touched.startDate}
            disabled={props.reportLoading}
            includeTime
          />
          <DateAndTimeInput
            label="End Date/Time"
            name="endDate"
            onChange={dateRangeForm.handleChange}
            onBlur={dateRangeForm.handleBlur}
            minDate={dateRangeForm.values.startDate}
            value={dateRangeForm.values.endDate}
            error={dateRangeForm.errors.endDate}
            touched={dateRangeForm.touched.endDate}
            disabled={
              dateRangeForm.values.startDate == undefined || props.reportLoading
            }
            includeTime
          />
        </div>
        <button
          type="button"
          aria-label="Include deleted entries"
          className={getBlockClass(ROOT_CLASS, 'deleted-entries-button')}
          onClick={() =>
            dateRangeForm.setValues({
              ...dateRangeForm.values,
              disabledEntries: !dateRangeForm.values.disabledEntries,
            })
          }
        >
          {dateRangeForm.values.disabledEntries ? (
            <FaRegCheckSquare />
          ) : (
            <FaRegSquare />
          )}
          Disabled Entries
        </button>
      </form>

      <section
        aria-label="Promotion date range report controls"
        className={getBlockClass(ROOT_CLASS, 'button-container')}
      >
        <button
          aria-label="generate report"
          className={`${getBlockClass(ROOT_CLASS, 'button')} primary`}
          onClick={() => props.generateReport(dateRangeForm.values)}
          disabled={
            !dateRangeForm.dirty ||
            !dateRangeForm.isValid ||
            props.reportLoading
          }
        >
          Generate
        </button>
        <button
          aria-label="clear results"
          className={`${getBlockClass(ROOT_CLASS, 'button')} secondary`}
          onClick={() => {
            dateRangeForm.resetForm()
            props.resetData()
          }}
          disabled={props.reportLoading}
        >
          Clear
        </button>
      </section>
    </section>
  )
}
