import * as React from 'react'
import { useFetchPlayerPromotionDrawingDetails } from '../../../../api/promotions-fetcher/use-fetch-promotion-entries'
import { PromotionDrawingDetailsModalUI } from './PromotionDrawingDetailsModalUI'

export type PromotionDrawingDetailsModalContainerProps = {
  playerId: string
  promotionId: string
  drawingId: string
  clearPromotionDrawing: () => void
}

export const PromotionDrawingDetailsModalContainer = (
  props: PromotionDrawingDetailsModalContainerProps
) => {
  const { data, entriesData, isLoading, error, handleLoadMore } =
    useFetchPlayerPromotionDrawingDetails(
      props.playerId,
      props.promotionId,
      props.drawingId
    )

  return (
    <PromotionDrawingDetailsModalUI
      promotionDrawingDetails={
        data ? { ...data, entries: entriesData } : undefined
      }
      isLoading={isLoading}
      isLoadingMore={isLoading && entriesData.length > 0}
      hideLoadingMore={!data || data.summary.entryCount <= entriesData.length}
      error={error}
      onLoadMore={handleLoadMore}
      handleCloseModal={props.clearPromotionDrawing}
    />
  )
}
