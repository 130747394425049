import * as React from 'react'
import { PlayerPromotionEntriesContainer } from './PromotionalEntryDetailsContainer'
import { PromotionDrawingDetailsModalContainer } from './PromotionDrawingDetailsModalContainer'

export type PlayerPromotionEntriesPageProps = {
  playerId: string
}
export const PlayerPromotionEntriesPage = (
  props: PlayerPromotionEntriesPageProps
) => {
  const [openPromotion, setOpenPromotion] = React.useState<string>()
  const [openDrawing, setOpenDrawing] = React.useState<string>()

  const setOpenPromotionDrawing = (promotion: string, drawingId: string) => {
    setOpenPromotion(promotion)
    setOpenDrawing(drawingId)
  }

  const clearPromotionDrawing = () => {
    setOpenPromotion(undefined)
    setOpenDrawing(undefined)
  }

  return (
    <>
      {openDrawing && openPromotion && (
        <PromotionDrawingDetailsModalContainer
          playerId={props.playerId}
          promotionId={openPromotion}
          drawingId={openDrawing}
          clearPromotionDrawing={clearPromotionDrawing}
        />
      )}
      <PlayerPromotionEntriesContainer
        playerId={props.playerId}
        setOpenPromotionDrawing={setOpenPromotionDrawing}
      />
    </>
  )
}
