import React from 'react'
import { formatCentsToDollars } from '../../../../util'
import './styles.scss'

const ROOT_CLASS = 'crq-pending-aggregate-totals'

export type PendingCheckQueueAggregateTotalsUIProps = {
  totalAnnuitiesInCents: number
  totalPromotionsInCents: number
  totalSeasonTicketsInCents: number
  totalOtherInCents: number
  totalGrossPaymentInCents: number
  includesOnHoldPayments: boolean
}

export const PendingCheckQueueAggregateTotalsUI = (
  props: PendingCheckQueueAggregateTotalsUIProps
) => {
  return (
    <div className={ROOT_CLASS}>
      <div className="d-flex">
        <h2 className={`outer-container-title`}>Aggregate Totals</h2>
        {!props.includesOnHoldPayments && <p
          className={` outer-container-subtitle font-italic mt-auto mb-auto ml-2`}
        >
          these totals do not include on hold payments
        </p>}
      </div>
      <div className={`d-flex justify-content-between`}>
        <div className={'d-flex'}>
          <div className={`label-value-container`}>
            <div className={`label`}>AN</div>
            <div className={`value`}>
              {formatCentsToDollars(props.totalAnnuitiesInCents)}
            </div>
          </div>
          <div className={`label-value-container`}>
            <div className={`label`}>Promotions</div>
            <div className={`value`}>
              {formatCentsToDollars(props.totalPromotionsInCents)}
            </div>
          </div>
          <div className={`label-value-container`}>
            <div className={`label`}>ST</div>
            <div className={`value`}>
              {formatCentsToDollars(props.totalSeasonTicketsInCents)}
            </div>
          </div>
          <div className={`label-value-container`}>
            <div className={`label`}>Other</div>
            <div className={`value`}>
              {formatCentsToDollars(props.totalOtherInCents)}
            </div>
          </div>
        </div>
        <div className={'d-flex'}>
          <div className={`label-value-container right-align`}>
            <div className={`label`}>Gross Payment</div>
            <div className={`value`}>
              {formatCentsToDollars(props.totalGrossPaymentInCents)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
