import React, {createContext, useState} from "react";
import { BannerContextInfo } from "./interfaces";

const defaultValue: BannerContextInfo = {
    message: "",
    error: false
}

type Timeout = ReturnType<typeof setTimeout>;

const BannerContext = createContext({bannerInfo:defaultValue, setBannerInfo:(param: BannerContextInfo) => {}, clearBannerInfo:() => {}}); 

const BannerProvider = ({children} : any) => {
    const [timeoutTracker, setTimeoutTracker] = useState<Timeout | undefined>();
    const [bannerInfo, setLocalBannerInfo] = useState<BannerContextInfo>(
        defaultValue
    );

    const clearBannerInfo = () => {
        setLocalBannerInfo({message: '', error: false});
    }

    const setBannerInfo = (param: BannerContextInfo) => {
        if(timeoutTracker){
            clearTimeout(timeoutTracker);
            setTimeoutTracker(undefined);
        }
        setLocalBannerInfo(param);
        setTimeoutTracker(setTimeout(clearBannerInfo, 5000));
    }

    return (
        <BannerContext.Provider value = {{bannerInfo, setBannerInfo, clearBannerInfo}}>
            {children}
        </BannerContext.Provider>
    );
}

const useBanner = () => React.useContext(BannerContext);

export {BannerProvider, BannerContext, useBanner};