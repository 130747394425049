import * as React from 'react'
import {
  AllPromotionsResponse,
  PromotionsDateRangeReportResponse,
  PromotionsDateRangeReportProps,
} from '../../../api/promotions-fetcher/constants'
import { PromotionsDateRangeReportFilterUI } from '../promotions-date-range-report-filter/promotions-date-range-report-filter-ui'
import {
  PromotionsDateRangeReportSummaryUI,
} from '../promotions-date-range-report-summary/promotions-date-range-report-summary-ui'
import './styles.scss'

type PromotionsDateRangeReportUIProps = {
  promotions?: AllPromotionsResponse
  reportLoading: boolean
  reportError: any
  reportData?: PromotionsDateRangeReportResponse
  generateReport: (props: PromotionsDateRangeReportProps) => void
  resetData: () => void
}

const ROOT_CLASS = 'promotions-date-range-report-ui'
export const PromotionsDateRangeReportUI = (
  props: PromotionsDateRangeReportUIProps
) => {
  const showSummary = props.reportLoading || props.reportData || props.reportError;
  return (
    <section className={ROOT_CLASS}>
      <PromotionsDateRangeReportFilterUI {...props} />
      {showSummary && <PromotionsDateRangeReportSummaryUI {...props} />}
    </section>
  )
}
