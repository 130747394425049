import * as React from 'react'
import { AllPromotionsResponse } from '../../../api/promotions-fetcher/constants'
import { PromotionsDateRangeReportUI } from './promotions-date-range-report-ui'
import { useFetchPromotionDateRangeReport } from '../../../api/promotions-fetcher/use-fetch-promotions-reporting'
type PromotionsDateRangeReportContainerProps = {
  promotions?: AllPromotionsResponse
}
export const PromotionsDateRangeReportContainer = (
  props: PromotionsDateRangeReportContainerProps
) => {
  const { isLoading, error, data, resetData, generateReport } =
    useFetchPromotionDateRangeReport()
  return (
    <PromotionsDateRangeReportUI
      promotions={props.promotions}
      reportLoading={isLoading}
      reportError={error}
      reportData={data}
      generateReport={generateReport}
      resetData={resetData}
    />
  )
}
