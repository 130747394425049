import * as React from 'react'
import {
  EntryMethod,
  PromotionEntryDetailsResponse,
} from '../../../api/promotions-fetcher/use-fetch-promotion-entries'
import './entry-table-styles.scss'

const CENTERED_CLASS = 'centered'
const TABLE_CLASS = 'promotion-entry-details-table'

const TableHeaders = [
  { columnText: 'Promotion' },
  { columnText: 'Draw' },
  {
    columnText: 'Draw Date',
    class: CENTERED_CLASS,
  },
  { columnText: 'Entries', class: CENTERED_CLASS },
  { columnText: 'Entry Method', class: CENTERED_CLASS },
]

export type EntryDetailsProps = Pick<
  PromotionEntryDetailsResponse,
  'promotions'
> & {
  entriesAreDisabled: boolean
}

export const EntryDetailsTable = (props: EntryDetailsProps) => (
  <table className={TABLE_CLASS}>
    <thead>
      <tr>
        {TableHeaders.map((header) => (
          <th key={header.columnText} className={header.class}>
            {header.columnText}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {props.promotions.map((row) => (
        <tr
          key={`${row.promotionName}-${row.drawingName}-${row.drawingNumber}}`}
        >
          <td>{row.promotionName}</td>
          <td>{`${row.drawingName} ${row.drawingNumber}`}</td>
          <td className={CENTERED_CLASS}>{row.drawingDate ?? '-'}</td>
          <td className={CENTERED_CLASS}>
            {props.entriesAreDisabled ? 'Disabled' : row.quantity}
          </td>
          <td className={CENTERED_CLASS}>
            {mapEntryMethodToString(row.entryMethod)}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

const mapEntryMethodToString = (method: EntryMethod) => {
  switch (method) {
    case EntryMethod.mobile:
      return 'Mobile Scan'
    case EntryMethod.web:
      return 'Manual Web'
    default:
      return 'Unknown'
  }
}
