import * as React from 'react'
import { GetFuturePaymentDetailsResponse } from '../../../../api/future-payment-details-fetcher/api-types'
import { formatCentsToDollars } from '../../../../util'
import './styles.scss'
import { FuturePaymentSource } from '../../../../api/future-payments-fetcher/api-types'

const ROOT_CLASS = 'future-payment-check-details'

export type FuturePaymentCheckDetailsUIProps = {
  futurePaymentDetailsData: GetFuturePaymentDetailsResponse
}

const FUTURE_PAYMENT_DISPLAY_SOURCE_MAP_FULL: {
  [key in FuturePaymentSource]: string
} = {
  ANNUITIES: 'ANNUITIES',
  PROMOTIONS: 'PROMOTIONS',
  SEASON_TICKETS: 'SEASON TICKETS',
  OTHER: 'OTHER'
}

export const FuturePaymentCheckDetailsUI = (props: FuturePaymentCheckDetailsUIProps) => {
  return (
    <div className={ROOT_CLASS}>
      <div className={`row-container-title`}>Check Details</div>
      <div className={`check-details-controls-container`}>
        <div className={`column-1`}>Annuity ID</div>
        <div className={`column-2`}>{props.futurePaymentDetailsData.annuityAccountId}</div>
        <div className={`column-1`}>Source</div>
        <div className={`column-2`}>{FUTURE_PAYMENT_DISPLAY_SOURCE_MAP_FULL[props.futurePaymentDetailsData.source]}</div>
        <div className={`column-1`}>Payment Type</div>
        <div className={`column-2`}>{props.futurePaymentDetailsData.paymentType}</div>
        <div className={`column-1`}>Gross Payment</div>
        <div className={`column-2`}>{formatCentsToDollars(props.futurePaymentDetailsData.grossPaymentInCents)}</div>
      </div>
    </div>
  )
}