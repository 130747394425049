import React from 'react'
import { Switch, useParams, withRouter } from 'react-router-dom'
import { permissions } from '../../../constants'
import { checkRenderPermissions } from '../../../helpers.js'
import { CommonLandingPage } from '../../CommonsPage/CommonLandingPage'
import {
  ButtonInfo,
  PageInfo,
} from '../../CommonsPage/CommonLandingPage/interfaces'
import { Forbidden409Page } from '../../CommonsPage/Forbidden409Page'
import { CheckRunHistoryPageContainer } from '../../FinancePages/CheckRunHistoryPage/check-run-history-page-container'
import { CheckRunQueuePageContainer } from '../../FinancePages/CheckRunQueuePage/check-run-queue-page-container'
import { CheckRunPaymentListPageContainer } from '../../FinancePages/check-run-payment-list-page/check-run-payment-list-page-container'
import { FuturePaymentDetailsPageContainer } from '../FuturePaymentDetailsPage/future-payment-details-page-container'
import { PaymentSearchPage } from '../PaymentSearchPage'
import { W2GPrintDetailsPage } from '../W2GPrintDetailsPage'
import { W2GPrintHistoryPage } from '../W2GPrintHistoryPage'
import { W2GQueuePage } from '../W2GQueuePage'
import {
  checkRunQueueRoutePath,
  futurePaymentsRoutePath,
  paymentsRoutePath,
  paymentsSearchRoutePath,
  w2gQueueRoutePath,
  reissueQueueRoutePath,
  reissueHistoryRoutePath,
  futureReissuePaymentsRoutePath,
} from './../../../config.js'
import { PrivateRoute } from './../../../routes.js'
import { Create5754SplitPageContainer } from '../Create5754SplitPage/create-5754-split-page-container'
import { PaymentDetailsPageContainer } from '../PaymentDetailsPage/payment-details-page-container'
import { ReissueQueuePageContainer } from '../../FinancePages/ReissueQueuePage/reissue-queue-page-container'
import { ReissueHistoryPageContainer } from '../../FinancePages/ReissueHistoryPage/reissue-history-page-container'
import { CreateVoidReissuePageContainer } from '../CreateVoidReissuePage/create-void-reissue-page-container'
import { FutureReissuePaymentDetailsPageContainer } from '../FutureReissuePaymentDetailsPage/future-reissue-payment-details-page-container'
import { FuturePaymentDetailsUpdatePageContainer } from '../FuturePaymentDetailsUpdatePage/future-payment-details-update-page-container'
import { ReviewW2gReissuePageContainer } from '../ReviewW2GReissueIndividualPage/review-w2g-reissue-page-container'

const PaymentsRoutesPage = () => {
  const userpolicies: any = localStorage.getItem('userpolicies')

  const landingObject: ButtonInfo[] = [
    {
      buttonTitle: 'Payment Search & Management',
      buttonPermission: `${permissions.CAN_SEE_GLOBAL_PAYMENTS_SEARCH}`,
      buttonRoute: `${paymentsSearchRoutePath}`,
    },
    {
      buttonTitle: 'W-2G Queue',
      buttonPermission: `${permissions.CAN_SEE_W2G_QUEUE}`,
      buttonRoute: `${w2gQueueRoutePath}`,
    },
    {
      buttonTitle: 'Check Run Queue',
      buttonPermission: `${permissions.CAN_SEE_CHECK_RUN_QUEUE}`,
      buttonRoute: `${checkRunQueueRoutePath}`,
    },
    {
      buttonTitle: 'Re-Issue Queue',
      buttonPermission: `${permissions.CAN_SEE_PENDING_REISSUES}`,
      buttonRoute: `${reissueQueueRoutePath}`,
    },
  ]

  const landingPageInfo: PageInfo = {
    title: 'Payments',
    policy: permissions.CAN_SEE_GLOBAL_PAYMENTS_MODULE,
    buttonData: landingObject,
    backButtonText: '',
  }

  return (
    <>
      <Switch>
        <PrivateRoute
          path={`${paymentsSearchRoutePath}`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_GLOBAL_PAYMENTS_SEARCH,
              JSON.parse(userpolicies)
            ) ? (
              <PaymentSearchPage />
            ) : (
              <Forbidden409Page />
            )
          }
        />

        <PrivateRoute
          path={`${w2gQueueRoutePath}/print-history`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_W2G_PRINT_HISTORY,
              JSON.parse(userpolicies)
            ) ? (
              <W2GPrintHistoryPage />
            ) : (
              <Forbidden409Page />
            )
          }
        />

        <PrivateRoute
          path={`${w2gQueueRoutePath}/print-details`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_W2G_PRINT_DETAILS,
              JSON.parse(userpolicies)
            ) ? (
              <W2GPrintDetailsPage
                sweepId={
                  props.location.pathname.split('/')[
                    props.location.pathname.split('/').length - 1
                  ]
                }
              />
            ) : (
              <Forbidden409Page />
            )
          }
        />

        <PrivateRoute
          path={`${w2gQueueRoutePath}`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_W2G_QUEUE,
              JSON.parse(userpolicies)
            ) ? (
              <W2GQueuePage />
            ) : (
              <Forbidden409Page />
            )
          }
        />

        <PrivateRoute
          path={`${paymentsRoutePath}/details/:internalPaymentId`}
          component={(_props: any) => {
            const params = useParams<{ internalPaymentId: string }>()
            return checkRenderPermissions(
              permissions.CAN_SEE_GLOBAL_PAYMENT_DETAILS,
              JSON.parse(userpolicies)
            ) ? (
              <PaymentDetailsPageContainer
                internalPaymentId={params.internalPaymentId}
              />
            ) : (
              <Forbidden409Page />
            )
          }}
        />

        <PrivateRoute
          path={`${paymentsRoutePath}/create-void-reissue/:internalPaymentId`}
          component={(props: any) => {
            const params = useParams<{ internalPaymentId: string }>()
            return checkRenderPermissions(
              permissions.CAN_VOID_AND_REISSUE_PAYMENT,
              JSON.parse(userpolicies)
            ) ? (
              <CreateVoidReissuePageContainer
                internalPaymentId={params.internalPaymentId}
              />
            ) : (
              <Forbidden409Page />
            )
          }}
        />

        <PrivateRoute
          path={`${paymentsRoutePath}/review-w2g-reissue/:paymentId`}
          component={(props: any) => {
            const params = useParams<{ paymentId: string }>()
            return checkRenderPermissions(
              permissions.CAN_REISSUE_W2G,
              JSON.parse(userpolicies)
            ) ? (
              <ReviewW2gReissuePageContainer paymentId={params.paymentId} />
            ) : (
              <Forbidden409Page />
            )
          }}
        />

        <PrivateRoute
          path={`${paymentsRoutePath}/create-5754-split/:internalPaymentId`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_CREATE_5754_SPLIT,
              JSON.parse(userpolicies)
            ) ? (
              <Create5754SplitPageContainer />
            ) : (
              <Forbidden409Page />
            )
          }
        />
        <PrivateRoute
          path={`${checkRunQueueRoutePath}/:checkRunId/payments`}
          component={(_props: any) => {
            const params = useParams<{ checkRunId: string }>()
            return checkRenderPermissions(
              permissions.CAN_VIEW_PAST_CHECK_RUN_PAYMENTS,
              JSON.parse(userpolicies)
            ) ? (
              <CheckRunPaymentListPageContainer id={params.checkRunId} />
            ) : (
              <Forbidden409Page />
            )
          }}
        />

        <PrivateRoute
          path={`${checkRunQueueRoutePath}/history`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_CHECK_RUN_HISTORY,
              JSON.parse(userpolicies)
            ) ? (
              <CheckRunHistoryPageContainer />
            ) : (
              <Forbidden409Page />
            )
          }
        />

        <PrivateRoute
          path={`${reissueHistoryRoutePath}`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_REISSUE_HISTORY,
              JSON.parse(userpolicies)
            ) ? (
              <ReissueHistoryPageContainer />
            ) : (
              <Forbidden409Page />
            )
          }
        />

        <PrivateRoute
          path={`${checkRunQueueRoutePath}`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_CHECK_RUN_QUEUE,
              JSON.parse(userpolicies)
            ) ? (
              <CheckRunQueuePageContainer />
            ) : (
              <Forbidden409Page />
            )
          }
        />

        <PrivateRoute
          path={`${futurePaymentsRoutePath}/details/:futurePaymentId`}
          component={(_props: any) => {
            const params = useParams<{ futurePaymentId: string }>()
            return checkRenderPermissions(
              permissions.CAN_SEE_FUTURE_PAYMENT_DETAILS,
              JSON.parse(userpolicies)
            ) ? (
              <FuturePaymentDetailsPageContainer
                futurePaymentId={params.futurePaymentId}
              />
            ) : (
              <Forbidden409Page />
            )
          }}
        />

        <PrivateRoute
          path={`${futurePaymentsRoutePath}/update/:futurePaymentId`}
          component={(_props: any) => {
            const params = useParams<{ futurePaymentId: string }>()
            return checkRenderPermissions(
              permissions.CAN_UPDATE_FUTURE_PAYMENT_DETAILS,
              JSON.parse(userpolicies)
            ) ? (
              <FuturePaymentDetailsUpdatePageContainer
                futurePaymentId={params.futurePaymentId}
              />
            ) : (
              <Forbidden409Page />
            )
          }}
        />

        <PrivateRoute
          path={`${futureReissuePaymentsRoutePath}/details/:futureReissuePaymentId`}
          component={(_props: any) => {
            const params = useParams<{ futureReissuePaymentId: string }>()
            return checkRenderPermissions(
              permissions.CAN_SEE_FUTURE_PAYMENT_DETAILS,
              JSON.parse(userpolicies)
            ) ? (
              <FutureReissuePaymentDetailsPageContainer
                futureReissuePaymentId={params.futureReissuePaymentId}
              />
            ) : (
              <Forbidden409Page />
            )
          }}
        />

        <PrivateRoute
          path={`${reissueQueueRoutePath}/`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_PENDING_REISSUES,
              JSON.parse(userpolicies)
            ) ? (
              <ReissueQueuePageContainer />
            ) : (
              <Forbidden409Page />
            )
          }
        />

        <PrivateRoute
          path={`${paymentsRoutePath}/`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_GLOBAL_PAYMENTS_MODULE,
              JSON.parse(userpolicies)
            ) ? (
              <CommonLandingPage {...landingPageInfo} />
            ) : (
              <Forbidden409Page />
            )
          }
        />
      </Switch>
    </>
  )
}
export default withRouter(PaymentsRoutesPage)
