import * as React from 'react'
import { useFetchPlayerPromotions } from '../../../../api/promotions-fetcher/use-fetch-promotion-entries'
import { PlayerPromotionEntriesUI } from './PromotionalEntryDetailsUI'

export type PlayerPromotionEntriesContainerProps = {
  playerId: string
  setOpenPromotionDrawing: (promotionId: string, drawingId: string) => void
}
export const PlayerPromotionEntriesContainer = (
  props: PlayerPromotionEntriesContainerProps
) => {
  const { isLoading, error, data } = useFetchPlayerPromotions(props.playerId)

  return (
    <PlayerPromotionEntriesUI
      setOpenPromotionDrawing={props.setOpenPromotionDrawing}
      promotions={data}
      isLoading={isLoading}
      error={error}
    />
  )
}
