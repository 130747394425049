import * as React from 'react'
import {
  getBlockClass,
  getModifierClass,
  joinClasses,
} from '../../../utilities/helpers'
import { ErrorMessageFieldCommonComponent } from '../ErrorMessageFieldCommonComponent'
import './styles.scss'

export type DateAndTimeInputProps = {
  label: string
  name: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur: (e: any) => void
  value: string
  error: string | undefined
  touched: boolean | undefined
  minDate?: string
  maxDate?: string
  disabled: boolean
  includeTime?: boolean
  includeSeconds?: boolean
  optionalLabel?: boolean
  greyOnDisabled?: boolean
  noValidate?: boolean
  tooltip?: JSX.Element
}

const ROOT_CLASS = 'date-and-time-input'
const CONTENT_CLASS = getBlockClass(ROOT_CLASS, 'content')

export const DateAndTimeInput = (props: DateAndTimeInputProps) => {
  const onChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e)
  }
  return (
    <div className={ROOT_CLASS}>
      <label
        className={getBlockClass(ROOT_CLASS, 'label')}
        htmlFor={props.name}
      >
        {props.label}
        {props.optionalLabel && (
          <span className={getBlockClass(ROOT_CLASS, 'optional-label')}>
            Optional
          </span>
        )}
        {props.tooltip}
      </label>
      <input
        id={props.name}
        className={joinClasses([
          CONTENT_CLASS,
          getModifierClass(
            CONTENT_CLASS,
            'grey',
            props.disabled && Boolean(props.greyOnDisabled)
          ),
        ])}
        type={props.includeTime ? 'datetime-local' : 'date'}
        min={props.minDate}
        max={props.maxDate}
        name={props.name}
        value={props.value}
        onChange={onChangeDate}
        onBlur={(e) => props.onBlur(e)}
        disabled={props.disabled}
        step={props.includeSeconds ? 1 : undefined}
        required
        formNoValidate={props.noValidate}
      />
      {props.error && props.touched && (
        <ErrorMessageFieldCommonComponent errorMessage={props.error} />
      )}
    </div>
  )
}
