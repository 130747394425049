import { CircularProgress } from '@material-ui/core'
import { useFormik } from 'formik'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { GenericErrorDialogComponent } from '../../../components/CommonComponents/GenericErrorDialogComponent'
import { ErrorMessageFieldCommonComponent } from '../../../components/CommonComponents/ErrorMessageFieldCommonComponent'
import { PaginationCommonComponent } from '../../../components/CommonComponents/PaginationCommonComponent'
import { permissions } from '../../../constants'
import { checkRenderPermissions } from '../../../helpers'
import goBackIcon from '../../../icons/Icon_arrows_carrot_white-left.svg'
import { keyPressedType } from '../../../services/Commons'
import styles from './css/styles.module.css'
import {
  generateYearEndReportBundleURL,
  generateYearEndReports,
  getPageData,
  onSubmit,
  setFieldValue,
  setVariables,
  validate,
} from './functions'
import { YearEndReportsInterface } from './interfaces'

export const YearEndReportingPage = () => {
  const history: any = useHistory()
  const location = useLocation()

  const PAGE_REFRESH_RATE = 120 // in seconds

  const userpolicies: any = localStorage.getItem('userpolicies')

  const [yearEndReports, setYearEndReports] =
    useState<YearEndReportsInterface>()

  const currentYear = new Date().getFullYear()
  const [yearDropdownList, setYearDropdownList] = useState<string[]>([])

  const formik = useFormik({
    initialValues: {
      year: '',
    },
    validate,
    onSubmit,
  })

  const activateSearchButton = formik.isValid && formik.dirty

  const [requestCompleted, setRequestCompleted] = useState<boolean>(true)
  const [reportBundleDownloadCompleted, setReportBundleDownloadCompleted] =
    useState<boolean>(true)

  const [currentPage, setCurrentPage] = useState<number>(0)
  const previousPageValue = useRef(currentPage) // Persists the current page# across renders

  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)

  const reportBundleStatus = {
    PENDING: 'pending',
    FAILED: 'failed',
    SUCCESS: 'success',
    EXPIRED: 'expired',
  }

  const fillYearDropdownList = () => {
    let yearDropdownList = [currentYear.toString()]
    for (let i = 1; i < 5; i++) {
      yearDropdownList.push((currentYear - i).toString())
    }
    setYearDropdownList(yearDropdownList)
  }

  useEffect(() => {
    fillYearDropdownList()
  }, [])

  // Save current page number so that when the page auto refreshes we can go directly to that page
  useEffect(() => {
    previousPageValue.current = currentPage
  }, [currentPage])

  // Auto-Refresh the page
  useEffect(() => {
    const interval = setInterval(() => {
      formik.submitForm()
    }, PAGE_REFRESH_RATE * 1000)
    return () => clearInterval(interval)
  }, [])

  const normalizePage = (totalPages: number, currentPage: number) => {
    const number: number = totalPages - currentPage * 10
    if (number > 10) {
      return currentPage * 10 + 10
    } else {
      return totalPages
    }
  }

  setVariables(
    formik,
    setYearEndReports,
    setCurrentPage,
    setShowErrorDialog,
    setRequestCompleted,
    setReportBundleDownloadCompleted,
    previousPageValue
  )

  return (
    <>
      {!requestCompleted || !reportBundleDownloadCompleted ? (
        <div
          style={{
            zIndex: 1000,
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'white',
          }}
        >
          <CircularProgress size="4em" color="inherit" thickness={2} />
        </div>
      ) : null}

      <div
        className={`${styles['pulltab-games-page-container']} ${styles['']}`}
      >
        <div
          tabIndex={0}
          className={`${styles['pulltab-games-page-back-button-container']} ${styles['']}`}
        >
          <img
            style={{
              cursor: 'pointer',
            }}
            src={goBackIcon}
            className={styles['']}
            alt="go back icon"
            onKeyDown={(ev: any) => {
              if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                history.goBack()
              }
            }}
            onClick={() => {
              history.goBack()
            }}
          />
          <div
            className={`${styles['pulltab-games-page-back-button-container-message']}`}
            style={{
              cursor: 'pointer',
            }}
            onKeyDown={(ev: any) => {
              if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
                history.goBack()
              }
            }}
            onClick={() => {
              history.goBack()
            }}
          >
            Back to Reporting
          </div>
        </div>
        <div
          className={`${styles['pulltab-games-page-ribbon-container']} ${styles['']}`}
        >
          <div
            tabIndex={0}
            className={`${styles['pulltab-games-page-ribbon-title']} ${styles['']}`}
          >
            Year End Reporting
          </div>
        </div>

        <div
          className={`${styles['pulltab-games-page-search-bar-and-notification-bar-container']} ${styles['']}`}
        >
          <div
            className={`${styles['pulltab-games-page-search-bar-container']} ${styles['']}`}
          >
            <div
              className={`${styles['pulltab-games-page-search-bar-title-container']}`}
            >
              <div
                className={`${styles['pulltab-games-page-search-bar-title']}`}
              >
                Generate Reports
              </div>
              <div
                className={`${styles['pulltab-games-page-search-bar-subtitle']}`}
              >
                IRS-1220, Reportable Payments Summary, Year End W-2G Summary
              </div>
            </div>
            <div
              className={`${styles['pulltab-games-page-search-bar-input-button-container']} ${styles['']}`}
            >
              <div
                className={`${styles['pulltab-games-page-search-bar-label-textbox-container']}`}
              >
                <div tabIndex={0} className={`${styles['mslc-label']}`}>
                  Select Year
                </div>
                <div className={`${styles.custom_select_div}`}>
                  <select
                    className={styles['custom_select']}
                    id="year"
                    name="year"
                    onChange={async (e: any) => {
                      await setFieldValue(e, formik)
                      setCurrentPage(0)
                      formik.submitForm()
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Select"
                  >
                    <option value="" selected disabled>
                      Select...
                    </option>
                    {yearDropdownList.map((year: string) => (
                      <option
                        selected={
                          formik.values.year.toLowerCase() ===
                          year.toLowerCase()
                        }
                        value={year}
                      >
                        {year}
                      </option>
                    ))}
                  </select>
                  <span className={`${styles.custom_arrow}`}></span>
                </div>
                {formik.errors.year ? (
                  <ErrorMessageFieldCommonComponent
                    errorMessages={formik.errors.year}
                  />
                ) : null}
              </div>
              <div
                tabIndex={0}
                className={`${styles['mslc-search-button']} ${styles['']}`}
                role="button"
                style={{
                  opacity: activateSearchButton ? '1' : '0.4',
                  cursor: activateSearchButton ? 'pointer' : 'not-allowed',
                }}
                onKeyDown={(ev: any) => {
                  if (
                    keyPressedType(ev) === 13 ||
                    keyPressedType(ev) === 0 ||
                    keyPressedType(ev) === 32
                  ) {
                    if (activateSearchButton) {
                      generateYearEndReports(formik.values)
                    }
                  }
                }}
                onClick={() => {
                  if (activateSearchButton) {
                    generateYearEndReports(formik.values)
                  }
                }}
              >
                Generate
              </div>
            </div>
          </div>
        </div>
        {!yearEndReports ? (
          <div
            className={`${styles['pulltab-games-page-search-no-results-container']}`}
          >
            <div
              className={`${styles['pulltab-games-page-search-no-results-container-text']}`}
            >
              Select a year to generate reports
            </div>
          </div>
        ) : (
          <div className={styles['table']}>
            <div className={styles['table-data-header']}>
              <div
                className={styles['table-data-header-text']}
                aria-live="assertive"
              >
                {`Displaying ${
                  yearEndReports.bundles.length <= 0 ? 0 : currentPage * 10 + 1
                }-${normalizePage(yearEndReports.bundles.length, currentPage)}
                            of ${yearEndReports.bundles.length} results`}
              </div>
            </div>

            <div
              className={`${styles['table-data-grid']} ${styles['table-data-label']}`}
            >
              <div>Date & Time Generated</div>
              <div>Generated by</div>
              <div>Year</div>
              <div>Status</div>
            </div>

            <div className={styles['table-data-body']}>
              {yearEndReports.bundles.length > 0 &&
                getPageData(yearEndReports.bundles, currentPage).map(
                  (e: any, i: number) => (
                    <div
                      key={i}
                      style={{
                        backgroundColor: i % 2 === 0 ? '' : 'rgb(30, 75, 117)',
                      }}
                      className={`${styles['table-data-grid']} ${styles['table-data-data']}`}
                    >
                      <div className={styles['table-data-data-text']}>
                        {moment(e.generatedDate).format('MM/DD/YYYY hh:mm A')}
                      </div>
                      <div className={styles['table-data-data-text']}>
                        {e.generatedBy}
                      </div>
                      <div className={styles['table-data-data-text']}>
                        {e.paymentYear}
                      </div>
                      <div className={styles['table-data-data-text']}>
                        {e.status}
                      </div>
                      <div className={styles['table-details-button-container']}>
                        {checkRenderPermissions(
                          permissions.CAN_DOWNLOAD_YEAR_END_REPORTS,
                          JSON.parse(userpolicies)
                        ) &&
                        (e.status.toLowerCase() ===
                          reportBundleStatus.PENDING ||
                          e.status.toLowerCase() ===
                            reportBundleStatus.SUCCESS) ? (
                          <div
                            role="button"
                            aria-pressed={'false'}
                            style={{
                              opacity:
                                e.status.toLowerCase() ===
                                reportBundleStatus.SUCCESS
                                  ? '1'
                                  : '0.4',
                              cursor:
                                e.status.toLowerCase() ===
                                reportBundleStatus.SUCCESS
                                  ? 'pointer'
                                  : 'not-allowed',
                            }}
                            onKeyDown={(en: any) => {
                              if (
                                keyPressedType(en) === 13 ||
                                keyPressedType(en) === 0 ||
                                keyPressedType(en) === 32
                              ) {
                                if (
                                  e.status.toLowerCase() ===
                                  reportBundleStatus.SUCCESS
                                ) {
                                  generateYearEndReportBundleURL(e.id)
                                }
                              }
                            }}
                            onClick={() => {
                              if (
                                e.status.toLowerCase() ===
                                reportBundleStatus.SUCCESS
                              ) {
                                generateYearEndReportBundleURL(e.id)
                              }
                            }}
                            className={styles['table-details-button']}
                          >
                            <div
                              aria-label={'Licensee Details Button.'}
                              className={styles['table-details-button-text']}
                            >
                              Download All
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )
                )}
            </div>
            <div className={styles['table-data-footer']}>
              <div
                aria-live="assertive"
                className={styles['table-page-label']}
              >{`Page ${currentPage + 1} 
                         of ${Math.ceil(
                           yearEndReports.bundles.length / 10
                         ).toString()}`}</div>
              {
                <PaginationCommonComponent
                  playerData={yearEndReports.bundles}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              }
            </div>
          </div>
        )}
      </div>
      {showErrorDialog ? (
        <GenericErrorDialogComponent setShowErrorDialog={setShowErrorDialog} />
      ) : null}
    </>
  )
}
