import React from 'react'
import { Switch, withRouter } from 'react-router-dom'
import { AllPlayslipsStepDownPermissions } from '../../api/playslips-fetcher/permissions'
import { PromotionTabPermissions } from '../../api/promotions-fetcher/permissions'
import { pageMessageDefault } from '../../components/CommonComponents/PageMessageCommonComponent'
import { PageMessageCommonContextComponent } from '../../components/CommonComponents/PageMessageCommonContextComponent'
import HeaderComponent from '../../components/HomeComponent/HeaderComponent'
import MenuContainerComponent from '../../components/HomeComponent/MenuComponent/MenuContainerComponent'
import {
  actionsRoutePath,
  administrationRoutePath,
  annuitiesRoutePath,
  charitableRoutePath,
  claimsSupportRoutePath,
  forbiddenRoutePath,
  knoledgeRoutePath,
  operationsBasePath,
  paymentsRoutePath,
  playerSupportRoutePath,
  promotionsBasePath,
  reportingRoutePath,
  servicesRoutePath,
  ticketSearchRoutePath,
} from '../../config.js'
import { permissions } from '../../constants.js'
import { checkRenderPermissions } from '../../helpers.js'
import { PrivateRoute } from '../../routes.js'
import { userHasAnyOfPermissions } from '../../util/access-helpers'
import ActionsRoutesPage from '../ActionsPages/ActionsRoutesPage'
import AdministrationsRoutesPage from '../AdministrationsPages/AdministrationsRoutesPage'
import AnnuitiesRoutesPage from '../AnnuitiesPages/AnnuitiesRoutes'
import CharitableRoutesPage from '../CharitableGamingPages/CharitableGamingRoutesPage'
import ClaimsSupportRoutesPage from '../ClaimsPages/ClaimsSupportRoutesPage'
import { Forbidden409Page } from '../CommonsPage/Forbidden409Page'
import { NotFound404Page } from '../CommonsPage/NotFound404Page'
import KnoledgeRoutesPage from '../KnoledgePages/KnoledgeRoutesPage'
import { OperationsRouter } from '../OperationsPages/operations-router'
import PaymentsRoutesPage from '../PaymentsPages/PaymentsRoutesPage'
import PlayersSupportRoutesPage from '../PlayersPages/PlayersSupportRoutesPage'
import { PromotionsRouter } from '../PromotionsPages/promotions-router'
import ReportingRoutesPage from '../ReportingPage/ReportingRoutesPage'
import ServiceRoutesPage from '../ServicePages/ServiceRoutesPage'
import TicketSearchRoutesPage from '../TicketSearch/TicketSearchRoutesPage'
import styles from './css/styles.module.css'

const HomePage = ({ props, storesObject }: any) => {
  const userpolicies: any = localStorage.getItem('userpolicies')

  return (
    <>
      <div
        onClick={() => {
          pageMessageDefault.message = ''
          pageMessageDefault.messageType = ''
        }}
        className={styles['page-container']}
      >
        <HeaderComponent />
        <div className={styles['layout-new-body-container']}>
          <MenuContainerComponent props={props} storesObject={storesObject} />
          <div className={styles['layout-new-body-pages']}>
            <PageMessageCommonContextComponent />
            <Switch>
              <PrivateRoute
                path={`${playerSupportRoutePath}`}
                component={(props: any) =>
                  checkRenderPermissions(
                    permissions.CAN_SEE_PLAYER_SUPPORT_NEW,
                    JSON.parse(userpolicies)
                  ) ? (
                    <PlayersSupportRoutesPage
                      storesObject={storesObject}
                      props={props}
                    />
                  ) : (
                    <Forbidden409Page />
                  )
                }
              />
              <PrivateRoute
                path={`${actionsRoutePath}`}
                component={(props: any) =>
                  checkRenderPermissions(
                    permissions.CAN_SEE_ACTIONS,
                    JSON.parse(userpolicies)
                  ) ? (
                    <ActionsRoutesPage
                      storesObject={storesObject}
                      props={props}
                    />
                  ) : (
                    <Forbidden409Page />
                  )
                }
              />
              <PrivateRoute
                path={`${claimsSupportRoutePath}`}
                component={(props: any) =>
                  checkRenderPermissions(
                    permissions.CAN_SEE_PLAYER_SUPPORT_NEW,
                    JSON.parse(userpolicies)
                  ) ? (
                    <ClaimsSupportRoutesPage
                      storesObject={storesObject}
                      props={props}
                    />
                  ) : (
                    <Forbidden409Page />
                  )
                }
              />
              <PrivateRoute
                path={`${reportingRoutePath}`}
                component={(props: any) =>
                  checkRenderPermissions(
                    permissions.CAN_SEE_PLAYER_SUPPORT_NEW,
                    JSON.parse(userpolicies)
                  ) ? (
                    <ReportingRoutesPage
                      storesObject={storesObject}
                      props={props}
                    />
                  ) : (
                    <Forbidden409Page />
                  )
                }
              />
              <PrivateRoute
                path={`${annuitiesRoutePath}`}
                component={(props: any) =>
                  checkRenderPermissions(
                    permissions.CAN_SEE_ANNUITIES,
                    JSON.parse(userpolicies)
                  ) ? (
                    <AnnuitiesRoutesPage
                      storesObject={storesObject}
                      props={props}
                    />
                  ) : (
                    <Forbidden409Page />
                  )
                }
              />
              <PrivateRoute
                path={`${charitableRoutePath}`}
                component={(props: any) =>
                  checkRenderPermissions(
                    permissions.CAN_SEE_CHARITABLE_GAMING,
                    JSON.parse(userpolicies)
                  ) ? (
                    <CharitableRoutesPage
                      storesObject={storesObject}
                      props={props}
                    />
                  ) : (
                    <Forbidden409Page />
                  )
                }
              />
              <PrivateRoute
                path={promotionsBasePath}
                component={(props: any) =>
                  userHasAnyOfPermissions(PromotionTabPermissions) ? (
                    <PromotionsRouter />
                  ) : (
                    <Forbidden409Page />
                  )
                }
              />
              <PrivateRoute
                path={operationsBasePath}
                component={(props: any) =>
                  userHasAnyOfPermissions(AllPlayslipsStepDownPermissions) ? (
                    <OperationsRouter />
                  ) : (
                    <Forbidden409Page />
                  )
                }
              />
              <PrivateRoute
                path={`${administrationRoutePath}`}
                component={(props: any) => (
                  <AdministrationsRoutesPage
                    storesObject={storesObject}
                    props={props}
                  />
                )}
              />

              <PrivateRoute
                path={`${ticketSearchRoutePath}`}
                component={(props: any) =>
                  checkRenderPermissions(
                    permissions.CAN_SEE_TICKETS_NEW,
                    JSON.parse(userpolicies)
                  ) ? (
                    <TicketSearchRoutesPage
                      storesObject={storesObject}
                      props={props}
                    />
                  ) : (
                    <Forbidden409Page />
                  )
                }
              />

              <PrivateRoute
                path={`${paymentsRoutePath}`}
                component={() =>
                  checkRenderPermissions(
                    permissions.CAN_SEE_GLOBAL_PAYMENTS_MODULE,
                    JSON.parse(userpolicies)
                  ) ? (
                    <PaymentsRoutesPage />
                  ) : (
                    <Forbidden409Page />
                  )
                }
              />

              <PrivateRoute
                path={`${knoledgeRoutePath}`}
                component={(props: any) => (
                  <KnoledgeRoutesPage
                    storesObject={storesObject}
                    props={props}
                  />
                )}
              />
              <PrivateRoute
                path={`${servicesRoutePath}`}
                component={(props: any) => (
                  <ServiceRoutesPage
                    storesObject={storesObject}
                    props={props}
                  />
                )}
              />
              <PrivateRoute
                path={`${forbiddenRoutePath}`}
                component={(props: any) => <Forbidden409Page />}
              />

              <PrivateRoute
                path={`/`}
                component={(props: any) => <NotFound404Page />}
              />
            </Switch>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(HomePage)
