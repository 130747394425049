import * as React from 'react'
import { AutoFocusInside } from 'react-focus-lock'
import moment from 'moment'
import { getIn } from 'formik'
import { PromotionType } from '../../../../../../api/promotions-fetcher/constants'
import { useFormArray } from '../../../../../../hooks/use-form-array'
import {
  getBlockClass,
  getModifierClass,
  joinClasses,
} from '../../../../../../utilities/helpers'
import { CustomDropdownField } from '../../../../../CommonComponents/custom-dropdown-component/custom-dropdown-ui'
import { DateAndTimeInput } from '../../../../../CommonComponents/date-and-time-input-component/date-and-time-input-ui'
import { SideBarTextArea } from '../../../../../SidebarFormComponents/side-bar-text-area-component/side-bar-text-area-ui'
import { SideBarTextInput } from '../../../../../SidebarFormComponents/side-bar-text-input-component/side-bar-text-input-ui'
import { PromotionCampaignTooltip } from '../../../promotion-campaign-tooltip/promotion-campaign-tooltip-ui'
import {
  FORMIK_DATE_FORMAT,
  PROMOTION_SIDEBAR_FORM_CLASS,
  PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
  PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
  PromotionCampaignBuilderSubFormProps,
  getDrawFilePrefix,
  promotionIsComplete,
  promotionIsNewOrPending,
} from '../helpers'
import { PromotionEntriesDistributionFormUI } from './entries-distribution-form-ui'
import '../styles.scss'

// TO DO: adjust filter as more promotion types are supported
const promotionTypes = (
  Object.keys(PromotionType) as Array<keyof typeof PromotionType>
).filter(
  (x) =>
    x === 'MANUAL_WINNER_ENTRY' ||
    x === 'SECOND_CHANCE' ||
    x === 'SCAN_TO_ENTER'
)

const promotionTypeOptions = [
  {
    key: '',
    value: '',
    display: '',
  },
  ...promotionTypes
    .map((type) => {
      return {
        key: type,
        value: PromotionType[type],
        display: PromotionType[type]
      }
    })
    .sort((a, b) => a.key.localeCompare(b.key)),
]

export const PromotionDetailsFormUI = (
  props: PromotionCampaignBuilderSubFormProps
) => {
  const updateGameIds = async (gameIds: string[]) => {
    await props.formik.setFieldValue('gameIds', gameIds)
  }

  const { addElement: addGameId, removeElement: removeGameId } =
    useFormArray<string>({
      defaultFieldValue: '',
      fieldArray: props.formik.values.gameIds,
      setFieldArray: updateGameIds,
    })

  return (
    <>
      <div
        className={getBlockClass(
          PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
          'promotion-details'
        )}
      >
        <AutoFocusInside>
          <div className={PROMOTION_SIDEBAR_FORM_ITEM_CLASS}>
            <SideBarTextInput
              label={'Promotion Title'}
              name={'name'}
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.name}
              error={props.formik.errors.name}
              touched={props.formik.touched.name}
              disabled={
                props.isLoading ||
                !props.canEditPromotionCampaigns ||
                promotionIsComplete(props.promotionStatus)
              }
              greyOnDisabled
            />
          </div>
        </AutoFocusInside>
        <div className={PROMOTION_SIDEBAR_FORM_ITEM_CLASS}>
          <SideBarTextArea
            label={
              <div
                className={getBlockClass(
                  PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
                  'label'
                )}
              >
                Promotion Description
                <PromotionCampaignTooltip
                  tooltipText="This description will be player facing for Scan to Enter, please keep this to 100 characters."
                  altText="Promotion File Prefix Tooltip"
                  widthPx={185}
                />
              </div>
            }
            name={'description'}
            onChange={props.formik.handleChange}
            onBlur={props.formik.handleBlur}
            value={props.formik.values.description}
            error={props.formik.errors.description}
            touched={props.formik.touched.description}
            disabled={
              props.isLoading ||
              !props.canEditPromotionCampaigns ||
              promotionIsComplete(props.promotionStatus)
            }
            maxLength={
              props.formik.values.promotionType === PromotionType.SCAN_TO_ENTER
                ? 100
                : undefined
            }
            greyOnDisabled
          />
        </div>
        <div className={PROMOTION_SIDEBAR_FORM_ITEM_CLASS}>
          <CustomDropdownField
            onChange={props.formik.handleChange}
            value={props.formik.values.promotionType}
            error={props.formik.errors.promotionType}
            touched={props.formik.touched.promotionType}
            onBlur={props.formik.handleBlur}
            id="promotion-type-dropdown"
            name="promotionType"
            options={promotionTypeOptions}
            disabled={
              props.isLoading ||
              !promotionIsNewOrPending(props.promotionStatus) ||
              !props.canEditPromotionCampaigns
            }
            label={'Promotion Type'}
            greyOnDisabled
          />
        </div>
        {props.formik.values.promotionType && (
          <>
            {[
              PromotionType.SECOND_CHANCE as string,
              PromotionType.SCAN_TO_ENTER as string,
            ].includes(props.formik.values.promotionType) && (
              <div className={PROMOTION_SIDEBAR_FORM_ITEM_CLASS}>
                <SideBarTextInput
                  label={
                    <div
                      className={getBlockClass(
                        PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
                        'label'
                      )}
                    >
                      Promotion File Prefix
                      <PromotionCampaignTooltip
                        tooltipText="To accurately fill this section, please use all caps, do not add spaces and/or underscores."
                        altText="Promotion File Prefix Tooltip"
                        widthPx={185}
                      />
                    </div>
                  }
                  name={`drawFilePrefix`}
                  onChange={props.formik.handleChange}
                  onBlur={props.formik.handleBlur}
                  value={props.formik.values.drawFilePrefix}
                  error={props.formik.errors.drawFilePrefix}
                  touched={props.formik.touched.drawFilePrefix}
                  disabled={
                    props.isLoading ||
                    !props.canEditPromotionCampaigns ||
                    !promotionIsNewOrPending(props.promotionStatus)
                  }
                  placeholderText={getDrawFilePrefix(
                    props.formik.values.promotionType
                  )}
                  greyOnDisabled
                />
              </div>
            )}
            <div className={PROMOTION_SIDEBAR_FORM_ITEM_CLASS}>
              <DateAndTimeInput
                tooltip={
                  <PromotionCampaignTooltip
                    tooltipText="For accurate scheduling, ensure that the chosen start date occurs before the selected end date."
                    altText="Promotion Start & End Date Tooltip"
                    widthPx={155}
                  />
                }
                label={'Promotion Start Date & Time'}
                name={'startDate'}
                onChange={props.formik.handleChange}
                onBlur={props.formik.handleBlur}
                value={props.formik.values.startDate}
                error={props.formik.errors.startDate}
                touched={props.formik.touched.startDate}
                maxDate={props.formik.values.endDate}
                minDate={moment().format(FORMIK_DATE_FORMAT)}
                disabled={
                  props.isLoading ||
                  !promotionIsNewOrPending(props.promotionStatus) ||
                  !props.canEditPromotionCampaigns
                }
                includeTime
                includeSeconds
                greyOnDisabled
              />
            </div>
            <div className={PROMOTION_SIDEBAR_FORM_ITEM_CLASS}>
              <DateAndTimeInput
                label={'Promotion End Date & Time'}
                name={'endDate'}
                onChange={props.formik.handleChange}
                onBlur={props.formik.handleBlur}
                value={props.formik.values.endDate}
                error={props.formik.errors.endDate}
                touched={props.formik.touched.endDate}
                minDate={props.formik.values.startDate}
                disabled={
                  props.isLoading ||
                  !props.canEditPromotionCampaigns ||
                  promotionIsComplete(props.promotionStatus)
                }
                includeTime
                includeSeconds
                optionalLabel={
                  props.formik.values.promotionType ===
                  PromotionType.SECOND_CHANCE
                }
                greyOnDisabled
              />
            </div>
            {props.formik.values.promotionType ===
              PromotionType.SECOND_CHANCE && (
              <>
                {props.formik.values.gameIds.map((gameId, index) => {
                  return (
                    <div
                      className={joinClasses([
                        PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
                        getModifierClass(
                          PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
                          'removable',
                          index > 0
                        ),
                      ])}
                      key={`game-id-${index}`}
                    >
                      <SideBarTextInput
                        label={
                          index === 0 ? (
                            <div
                              className={getBlockClass(
                                PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
                                'label'
                              )}
                            >
                              Game ID
                              <PromotionCampaignTooltip
                                tooltipText="The game number is a 4 digit number associated with the Second Chance game."
                                altText="Game ID Tooltip"
                              />
                            </div>
                          ) : (
                            'Game ID'
                          )
                        }
                        name={`gameIds[${index}]`}
                        onChange={props.formik.handleChange}
                        onBlur={props.formik.handleBlur}
                        value={gameId}
                        error={getIn(props.formik.errors, `gameIds[${index}]`)}
                        touched={getIn(
                          props.formik.touched,
                          `gameIds[${index}]`
                        )}
                        disabled={
                          props.isLoading ||
                          !props.canEditPromotionCampaigns ||
                          !promotionIsNewOrPending(props.promotionStatus)
                        }
                        greyOnDisabled
                        placeholderText="0123"
                        onRemove={
                          index > 0 &&
                          promotionIsNewOrPending(props.promotionStatus)
                            ? () => removeGameId(index)
                            : undefined
                        }
                        removeButtonAriaLabel={`Remove Game ID ${index}`}
                      />
                    </div>
                  )
                })}
                {props.canEditPromotionCampaigns &&
                  promotionIsNewOrPending(props.promotionStatus) && (
                    <div
                      className={getBlockClass(
                        PROMOTION_SIDEBAR_FORM_CLASS,
                        'add-game-id-button-container'
                      )}
                    >
                      <button
                        type="button"
                        className={getBlockClass(
                          PROMOTION_SIDEBAR_FORM_CLASS,
                          'add-game-id-button'
                        )}
                        aria-label="Add game ID"
                        disabled={props.isLoading}
                        onClick={addGameId}
                      >
                        Add Game ID
                      </button>
                    </div>
                  )}
              </>
            )}
          </>
        )}
      </div>
      {props.formik.values.promotionType === PromotionType.SECOND_CHANCE && (
        <PromotionEntriesDistributionFormUI {...props} />
      )}
    </>
  )
}
