import * as React from 'react'
import { PlayerPromotionDrawingDetailsResponse } from '../../../../api/promotions-fetcher/use-fetch-promotion-entries'
import { LoadingDots } from '../../../../components/CommonComponents/loading-dots/loading-dots-ui'
import {
  getBlockClass,
  getModifierClass,
  joinClasses,
} from '../../../../utilities/helpers'
import './drawing-details-table-styles.scss'

const TABLE_CLASS = 'promotion-drawing-details-table'

const TableHeaders = [
  { columnText: 'Ticket Number' },
  { columnText: 'Ticket Entry Method' },
  { columnText: 'Entries' },
  { columnText: 'Entry Date' },
]

export type DrawingDetailsTableProps = Pick<
  PlayerPromotionDrawingDetailsResponse,
  'entries'
> & {
  onLoadMore: () => void
  isLoadingMore: boolean
  hideLoadingMore: boolean
}

export const DrawingDetailsTable = (props: DrawingDetailsTableProps) => (
  <div className={getBlockClass(TABLE_CLASS, 'table-container')}>
    <table
      className={joinClasses([
        TABLE_CLASS,
        getModifierClass(TABLE_CLASS, 'no-load-more', props.hideLoadingMore),
      ])}
    >
      <thead>
        <tr>
          {TableHeaders.map((header) => (
            <th key={header.columnText}>{header.columnText}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.entries.map((row) => (
          <tr key={row.ticketNumber}>
            <td>{row.ticketNumber}</td>
            <td>{row.entryMethod}</td>
            <td>{row.entryCount}</td>
            <td>{row.entryDate}</td>
          </tr>
        ))}
      </tbody>
    </table>
    {!props.hideLoadingMore && (
      <button
        className={getBlockClass(TABLE_CLASS, `load-more`)}
        onClick={props.onLoadMore}
        disabled={props.isLoadingMore}
      >
        {props.isLoadingMore ? (
          <LoadingDots id="promotion-drawing-details-loading" color="#FFFFFF" />
        ) : (
          'Load More'
        )}
      </button>
    )}
  </div>
)
