import * as React from 'react'
import { useHistory } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import {
  AllPromotionsResponse,
  PromotionCampaignStatusOrder,
  PromotionResponse,
} from '../../../api/promotions-fetcher/constants'
import { AsyncContainerUI } from '../../../components/CommonComponents/async-container-component/async-container-ui'
import { BackButtonUI } from '../../../components/CommonComponents/BackButtonComponent/back-button-ui'
import { GenericTableUI } from '../../../components/CommonComponents/generic-table-components/generic-table-ui'
import { GenericSidebarFormMode } from '../../../components/GenericSidebar/generic-sidebar-ui'
import { PromotionCampaignEndCampaignContainer } from '../../../components/PromotionsComponents/promotion-campaign-builder/promotion-campaign-modals/end-campaign/promotion-campaign-end-campaign-container'
import { PromotionCampaignScheduleDrawingContainer } from '../../../components/PromotionsComponents/promotion-campaign-builder/promotion-campaign-modals/schedule-drawing/promotion-campaign-schedule-drawing-container'
import { PromotionCampaignBuilderSidebarContainer } from '../../../components/PromotionsComponents/promotion-campaign-builder/promotion-campaign-sidebar/promotion-campaign-sidebar-container'
import { winnersManagementRoutePath } from '../../../config.js'
import { getBlockClass } from '../../../utilities/helpers'
import {
  mapPromotionCampaignDataToRow,
  promotionCampaignTableColumnMap,
} from './helpers'
import './styles.scss'

const ROOT_CLASS = 'promotion-campaign-builder-page'
const HEADER_CLASS = `${ROOT_CLASS}-header`

export type PromotionCampaignBuilderPageUIProps = {
  data?: AllPromotionsResponse
  isLoading: boolean
  error?: string
  fetchAllPromotions: () => Promise<void>
  disableAdminActions: boolean
  disableScheduleDrawingAction: boolean
}

export const PromotionCampaignBuilderPageUI = (
  props: PromotionCampaignBuilderPageUIProps
) => {
  const [sidebarFormMode, setSidebarFormMode] =
    React.useState<GenericSidebarFormMode>(GenericSidebarFormMode.CLOSED)
  const [openPromotionCampaign, setOpenPromotionCampaign] =
    React.useState<PromotionResponse>()
  const [openEndPromotionId, setOpenEndPromotionId] = React.useState<string>()
  const [openScheduleDrawingPromotion, setOpenScheduleDrawingPromotion] =
    React.useState<PromotionResponse>()
  const history = useHistory()

  const setFormMode = (
    formMode: GenericSidebarFormMode,
    promotion?: PromotionResponse
  ) => {
    setSidebarFormMode(formMode)
    setOpenPromotionCampaign(promotion)
  }

  const sortedData = sortBy(props.data ?? [], [
    (item: PromotionResponse) => PromotionCampaignStatusOrder[item.status],
    (item: PromotionResponse) => moment(item.endDate),
  ])
  const mappedData = sortedData.map((promotion) =>
    mapPromotionCampaignDataToRow(
      promotion,
      props.disableAdminActions,
      props.disableScheduleDrawingAction,
      () => setFormMode(GenericSidebarFormMode.EDIT, promotion),
      () => setOpenEndPromotionId(promotion.id),
      () => history.push(`${winnersManagementRoutePath}/${promotion.id}`),
      () => setOpenScheduleDrawingPromotion(promotion)
    )
  )

  return (
    <>
      {sidebarFormMode !== GenericSidebarFormMode.CLOSED && (
        <PromotionCampaignBuilderSidebarContainer
          formMode={sidebarFormMode}
          closeSidebar={() =>
            setFormMode(GenericSidebarFormMode.CLOSED, undefined)
          }
          promotionId={openPromotionCampaign?.id}
          refreshPromotions={props.fetchAllPromotions}
          promotionStatus={openPromotionCampaign?.status}
        />
      )}
      {openEndPromotionId && (
        <PromotionCampaignEndCampaignContainer
          closeModal={() => setOpenEndPromotionId(undefined)}
          promotionId={openEndPromotionId}
          fetchAllPromotions={props.fetchAllPromotions}
        />
      )}
      {openScheduleDrawingPromotion && (
        <PromotionCampaignScheduleDrawingContainer
          closeModal={() => setOpenScheduleDrawingPromotion(undefined)}
          promotion={openScheduleDrawingPromotion}
          fetchAllPromotions={props.fetchAllPromotions}
        />
      )}
      <section className={ROOT_CLASS}>
        <BackButtonUI message="Back to Promotions" removeLeftMargin />
        <header className={HEADER_CLASS}>
          <div className={getBlockClass(HEADER_CLASS, 'title')}>
            Promotion Campaign Builder
          </div>
          <button
            className={getBlockClass(HEADER_CLASS, 'create-button')}
            onClick={() => setSidebarFormMode(GenericSidebarFormMode.NEW)}
            aria-label="Create Promotion Campaign"
            disabled={props.disableAdminActions}
          >
            <div>Create</div>
          </button>
        </header>
        <div className={getBlockClass(ROOT_CLASS, 'content')}>
          <AsyncContainerUI
            isLoading={props.isLoading}
            error={props.error}
            errorTextSize={15}
            errorTextColor="white"
            color="white"
          >
            <GenericTableUI
              columnMap={promotionCampaignTableColumnMap}
              rowData={mappedData}
              useActionMenu
              usePages
              rowsPerPage={6}
              customRowCellStyling={{ padding: '30px 16px' }}
              customHeaderCellStyling={{ paddingLeft: '16px' }}
            />
          </AsyncContainerUI>
        </div>
      </section>
    </>
  )
}
