import React, { useState } from 'react'
import { FuturePaymentSource } from '../../../../api/future-payments-fetcher/api-types'
import {
  GetPendingPaymentsResponse,
  PendingPayment,
} from '../../../../api/pending-payments-fetcher/api-types'
import { formatCentsToDollars } from '../../../../util'
import { MaskedSsnUI } from '../../../CommonComponents/MaskedSsnComponent/masked-ssn-ui'
import {
  SearchResultsTableColumnMap,
  SearchResultsTableUI,
} from '../../../CommonComponents/SearchResultsTableComponent/search-results-table-ui'
import { OptionallyRenderSourceGuideCTA } from '../../SourceGuideButtonComponent'
import { CheckRunQueueSourceGuideUI } from '../check-run-queue-source-guide/check-run-queue-source-guide-ui'
import { generateFuturePaymentsListCTA } from '../future-payments-list-row-cta/generate-future-payments-list-row-cta'
import { LatestCheckRun } from '../latest-check-run/latest-check-run-container'
import { PendingCheckQueueAggregateTotalsUI } from '../pending-check-queue-aggregate-totals/pending-check-queue-aggregate-totals-ui'
import './styles.scss'
import { FutureTaxAdjustmentsUI } from '../future-tax-adjustments/future-tax-adjustments-ui'
import { TaxAdjustmentStatus } from '../../../../api/check-run-payments-fetcher/api-types'

const ROOT_CLASS = 'check-run-queue-pending-list'

export type PendingCheckRunQueueDisplayRow = Omit<
  PendingPayment,
  'grossPaymentInCents' | 'source' | 'tin'
> & {
  grossPaymentDisplay: string
  sourceDisplay: string
  tin: React.JSX.Element
}

const PENDING_CHECK_RUN_QUEUE_DISPLAY_SOURCE_MAP: {
  [key in FuturePaymentSource]: string
} = {
  ANNUITIES: 'AN',
  PROMOTIONS: 'PR',
  SEASON_TICKETS: 'ST',
  OTHER: 'OTR',
}

const PENDING_CHECK_QUEUE_COLUMN_MAP: SearchResultsTableColumnMap<PendingCheckRunQueueDisplayRow> =
  [
    { dataKey: 'name', label: 'Payee Name', width: 20 },
    { dataKey: 'tin', label: 'TIN', width: 15 },
    { dataKey: 'grossPaymentDisplay', label: 'Gross Payment', width: 15 },
    { dataKey: 'annuityAccountId', label: 'Annuity ID', width: 15 },
    { dataKey: 'sourceDisplay', label: 'Source', width: 20 },
  ]

const mapPendingPaymentsToDisplayRows = (
  pendingPayments: PendingPayment[]
): PendingCheckRunQueueDisplayRow[] => {
  return pendingPayments.map((pendingPayment) => {
    return {
      futurePaymentId: pendingPayment.futurePaymentId,
      name: pendingPayment.name,
      tin: <MaskedSsnUI ssn={pendingPayment.tin} />,
      annuityAccountId: pendingPayment.annuityAccountId,
      sourceDisplay:
        PENDING_CHECK_RUN_QUEUE_DISPLAY_SOURCE_MAP[pendingPayment.source],
      grossPaymentDisplay: formatCentsToDollars(
        pendingPayment.grossPaymentInCents
      ),
    }
  })
}

export type PendingCheckQueueUIProps = {
  pendingPaymentsData: GetPendingPaymentsResponse
}

export const PendingCheckQueueUI = (props: PendingCheckQueueUIProps) => {
  const [showPaymentSourceGuide, setPaymentSourceGuide] =
    useState<boolean>(false)

  return <>
    <div className={ROOT_CLASS}>
      <div className={`${ROOT_CLASS}_pending-check-queue-header`}>
        <span className={`${ROOT_CLASS}_latest-check-run-container`}>
          <LatestCheckRun />
        </span>
        <PendingCheckQueueAggregateTotalsUI
          totalAnnuitiesInCents={
            props.pendingPaymentsData.totalAnnuitiesPaymentsInCents
          }
          totalPromotionsInCents={
            props.pendingPaymentsData.totalPromotionsPaymentsInCents
          }
          totalSeasonTicketsInCents={
            props.pendingPaymentsData.totalSeasonTicketsPaymentsInCents
          }
          totalOtherInCents={
            props.pendingPaymentsData.totalOtherPaymentsInCents
          }
          totalGrossPaymentInCents={
            props.pendingPaymentsData.totalGrossPaymentsInCents
          }
          includesOnHoldPayments={false}
        />
      </div>
      <hr className={`${ROOT_CLASS}-horizontal-line`} />
      <SearchResultsTableUI
        rowData={mapPendingPaymentsToDisplayRows(
          props.pendingPaymentsData.futurePayments
        )}
        columnMap={PENDING_CHECK_QUEUE_COLUMN_MAP}
        generateRowCTA={generateFuturePaymentsListCTA}
        additionalResults={false}
        noDataMessage="There are no pending checks at this time"
      >
        <OptionallyRenderSourceGuideCTA
          setPaymentSourceGuide={setPaymentSourceGuide}
        />
      </SearchResultsTableUI>
      {showPaymentSourceGuide && (
        <CheckRunQueueSourceGuideUI
          setPaymentSourceGuide={setPaymentSourceGuide}
        />
      )}
    </div>
    <FutureTaxAdjustmentsUI 
      taxAdjustments={props.pendingPaymentsData.taxAdjustments}
    />
  </>
}
