import * as React from 'react'
import {
  PromotionResponse,
  PromotionStatusColors,
  PromotionType,
} from '../../../api/promotions-fetcher/constants'
import { GenericTableActionMenu } from '../../../components/CommonComponents/generic-table-components/generic-table-row/generic-table-row-action-menu/generic-table-row-action-menu-ui'
import {
  GenericTableColumnMap,
  GenericTableColumnType,
} from '../../../components/CommonComponents/generic-table-components/generic-table-row/generic-table-row-ui'
import { promotionIsComplete } from '../../../components/PromotionsComponents/promotion-campaign-builder/promotion-campaign-sidebar/sidebar-forms/helpers'
import { PromotionDrawingGroupTooltipUI } from '../../../components/PromotionsComponents/promotion-drawing-tooltip/promotion-drawing-tooltip-ui'
import { getModifierClass } from '../../../utilities/helpers'

export const getFormattedPromotionID = (promotionId: string) =>
  promotionId.padStart(5, '0')

export type PromotionCampaignTableRow = {
  promotion: JSX.Element
  promotionType: JSX.Element
  status: string
  drawings: JSX.Element
  actionMenu: GenericTableActionMenu
}

export const promotionCampaignTableColumnMap: GenericTableColumnMap<PromotionCampaignTableRow> =
  [
    {
      key: 'promotion',
      columnText: 'Promotion',
      columnType: GenericTableColumnType.DEFAULT,
    },
    {
      key: 'promotionType',
      columnText: 'Promotion Type',
      columnType: GenericTableColumnType.DEFAULT,
    },
    {
      key: 'status',
      columnText: 'Status',
      columnType: GenericTableColumnType.STATUS,
      statusMap: PromotionStatusColors,
    },
    {
      key: 'drawings',
      columnText: 'Drawings',
      columnType: GenericTableColumnType.DEFAULT,
    },
  ]

export const mapPromotionCampaignDataToRow = (
  promotion: PromotionResponse,
  disableAdminActions: boolean,
  disableScheduleDrawingAction: boolean,
  setEditMode: () => void,
  openEndModal: () => void,
  navigateToWinnersManagement: () => void,
  openScheduleDrawingModal: () => void
): PromotionCampaignTableRow => {
  const activeDrawingGroups =
    promotion.drawingGroups?.filter((group) =>
      group.drawings.some((drawing) => drawing.isActive)
    ) ?? []

  return {
    promotion: (
      <div className={'promotion-table-div'}>
        <span className={getModifierClass('promotion-table-div', 'bold')}>
          {promotion.name}
        </span>
        <span>ID {getFormattedPromotionID(promotion.id)}</span>
      </div>
    ),
    promotionType: (
      <div className={'promotion-table-div'}>
        <span>{PromotionType[promotion.type]}</span>
        {promotion.games.length > 0 &&
          promotion.games.map((game) => (
            <span
              key={`game-id-${game.id}`}
              className={getModifierClass('promotion-table-div', 'italic')}
            >
              Game ID {game.gameNumber}
            </span>
          ))}
      </div>
    ),

    status: promotion.status,
    drawings: (
      <>
        {promotion.drawingGroups.map((drawingGroup) => (
          <PromotionDrawingGroupTooltipUI
            key={`drawing-group-${drawingGroup.id}`}
            drawingGroup={drawingGroup}
            promotionStatus={promotion.status}
            showHeader
          />
        ))}
      </>
    ),

    actionMenu: {
      ariaLabel: `Actions for ${promotion.name}`,
      menuItems: [
        {
          onClick: setEditMode,
          label: promotionIsComplete(promotion.status)
            ? 'View Campaign'
            : 'Edit/View Campaign',
          disabled: false,
          ariaLabel: `Edit/View Promotion Campaign ${promotion.name}`,
          key: 'edit_view_promotion',
        },
        {
          onClick: openEndModal,
          label: 'End Campaign',
          disabled: false,
          ariaLabel: `End promotion campaign ${promotion.name}`,
          key: 'end',
          hideMenuItem:
            disableAdminActions || promotionIsComplete(promotion.status),
        },
        {
          onClick: navigateToWinnersManagement,
          label: 'Winners Management',
          disabled: false,
          ariaLabel: `View Winners Management for ${promotion.name}`,
          key: 'winners_management',
        },
        {
          onClick: openScheduleDrawingModal,
          label: 'Schedule Drawing End',
          disabled: false,
          ariaLabel: `Schedule Drawing End for ${promotion.name}`,
          key: 'schedule_drawing_end',
          hideMenuItem:
            disableScheduleDrawingAction || activeDrawingGroups.length == 0,
        },
      ],
    },
  }
}
