import * as React from 'react'
import { PromotionsDateRangeReportResponse } from '../../../api/promotions-fetcher/constants'
import { getBlockClass } from '../../../utilities/helpers'
import { AsyncContainerUI } from '../../CommonComponents/async-container-component/async-container-ui'
import './styles.scss'

type PromotionsDateRangeReportSummaryProps = {
  reportLoading: boolean
  reportError: any
  reportData?: PromotionsDateRangeReportResponse
}

type SummaryRow = {
  label: string
  value: number
}

const mapToDataPoints = (
  summary?: PromotionsDateRangeReportResponse
): SummaryRow[] => {
  if (!summary) {
    return []
  }
  return [
    {
      label: 'Number of Tickets',
      value: summary.totalScans,
    },
    {
      label: 'Number of Non-Winning Tickets',
      value: summary.numberTickets,
    },
    {
      label: 'Number of Entries',
      value: summary.numberEntries,
    },
    {
      label: 'Unique Player Participation',
      value: summary.uniquePlayers,
    },
    {
      label: 'Manual Entry via Web',
      value: summary.manualEntries,
    },
    {
      label: 'Scan Entry via App',
      value: summary.scanEntries,
    },
  ]
}
const ROOT_CLASS = 'promotions-date-range-report-summary'
export const PromotionsDateRangeReportSummaryUI = (
  props: PromotionsDateRangeReportSummaryProps
) => {
  return (
    <section
      aria-label="Promotions date range report summary"
      className={ROOT_CLASS}
    >
      <AsyncContainerUI
        isLoading={props.reportLoading}
        color="white"
        error={props.reportError}
        fitted={true}
        circularProgressSizeEm={6}
      >
        <header className={getBlockClass(ROOT_CLASS, 'header')}>Summary</header>
        <table className={getBlockClass(ROOT_CLASS, 'table')}>
          <tbody>
            {mapToDataPoints(props.reportData).map((row) => (
              <tr className={getBlockClass(ROOT_CLASS, 'row')} key={row.label}>
                <td
                  className={getBlockClass(ROOT_CLASS, 'cell')}
                  aria-label="label"
                >
                  {row.label}
                </td>
                <td
                  className={getBlockClass(ROOT_CLASS, 'cell')}
                  aria-label="value"
                >
                  {row.value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </AsyncContainerUI>
    </section>
  )
}
